<template>
    <div id="navbar-top" class="d-none d-lg-block">
        <nav class="navbar nav-rnd navbar-expand-lg top-sticky">
            <router-link v-if="!isStoreMode" class="navbar-brand" to="/">
                <img alt="willyou.net-logo" src="../../../assets/images/navbar/willyou.svg" class="navbar-brand-logo" />
            </router-link>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav divider-left mr-auto">
                    <li v-if="$route.name !== 'minisite'" class="nav-item nav-item-rnd">
                        <router-link
                            v-if="(currentStore && currentStore.onlyLead) || (!isStoreMode && (!currentStore || !currentStore.companyName || isDiamondsPage))"
                            class="nav-link"
                            :to="findJewelerLink">
                            <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="icon/plus" fill="#767d98">
                                        <path
                                            d="M2,2 L22,2 L22,22 L2,22 L2,2 Z M3,3 L3,21 L21,21 L21,3 L3,3 Z M12.75,12.75 L12.75,18 L11.25,18 L11.25,12.75 L6,12.75 L6,11.25 L11.25,11.25 L11.25,6 L12.75,6 L12.75,11.25 L18,11.25 L18,12.75 L12.75,12.75 Z"
                                            id="Rectangle-Copy-5"></path>
                                    </g>
                                </g>
                            </svg>
                            <span> Jewelry stores near you</span>
                        </router-link>
                        <router-link
                            class="nav-link py-0"
                            v-if="currentStore && currentStore.companyName && !currentStore.onlyLead && !isDiamondsPage"
                            to=""
                            @click.passive="routeToStore(getCurrentStoreUrl)">
                            <img
                                v-if="currentStore.images && currentStore.images.logo"
                                alt="willyou"
                                :src="currentStore.images.logo"
                                class="navbar-brand-logo float-left mr-3" />
                            <div class="d-table-cell">
                                <span class="d-flex">{{ currentStore.companyName }}</span>
                                <span class="d-flex">{{ getSemiAddress }}</span>
                            </div>
                        </router-link>
                    </li>
                </ul>
                <ul class="navbar-nav divider-left ml-auto">
                    <li class="nav-item nav-item-rnd">
                        <div v-if="$route.name !== 'minisite' && !storeMode" class="nav-link icon-btn" :to="wishlistLink">
                            <svg
                                class="rnd-svg-icon"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <path
                                        d="M19.591974,6.36189838 C18.6823145,5.48377689 17.4733499,5 16.1872102,5 C14.9010705,5 13.6914348,5.48377689 12.7824464,6.36189838 L12.0009647,7.11656442 L11.2198185,6.36222241 C10.3104946,5.48410092 9.10119444,5.00032403 7.81505474,5.00032403 C6.52891503,5.00032403 5.31961487,5.48410092 4.41062649,6.36222241 C3.50130256,7.2403439 3,8.40814828 3,9.65015553 C3,10.8921628 3.50096702,12.0599672 4.41062649,12.9380887 L11.5261701,19.8101184 C11.6573678,19.9368141 11.8288307,20 12.0006291,20 C12.1724276,20 12.3438905,19.9368141 12.4750882,19.8101184 L19.591974,12.9380887 C21.469342,11.1248164 21.469342,8.17517066 19.591974,6.36189838 Z M18.6430559,12.0214076 L12.0006291,18.4352588 L5.3588735,12.0214076 C4.70288521,11.3879288 4.34150445,10.5457746 4.34150445,9.6498315 C4.34150445,8.75388836 4.70288521,7.91173421 5.3588735,7.27825542 C6.0148618,6.64477664 6.88694035,6.29612028 7.81438365,6.29612028 C8.74182694,6.29612028 9.61424104,6.64510067 10.2702293,7.27825542 L11.5258345,8.49109997 C11.7878943,8.74416746 12.2126929,8.74416746 12.4750882,8.49109997 L13.731029,7.27825542 C14.3870172,6.64477664 15.2594313,6.29579625 16.1868746,6.29579625 C17.1143179,6.29579625 17.986732,6.64477664 18.6427203,7.27825542 C19.9969754,8.58604078 19.9969754,10.7139463 18.6430559,12.0214076 Z"
                                        id="path-1"></path>
                                </defs>
                                <g id="Symbols" stroke="none" stroke-width="1" :fill="$route.name === 'wishlist' ? '#ff4967' : 'none'" fill-rule="evenodd">
                                    <g id="icon/Wishlist">
                                        <mask id="mask-2" fill="white">
                                            <use xlink:href="#path-1"></use>
                                        </mask>
                                        <use id="Mask" xlink:href="#path-1"></use>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <button
                            v-if="$route.name === 'minisite'"
                            aria-label="join now"
                            class="btn rnd-secondary-btn balance-width text-uppercase"
                            v-scroll-to="'#join-now'">
                            login
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "navbar-top",
    data() {
        return {
            showWishlistEmptyAlert: false,
        };
    },
    computed: {
        ...mapState("willyou", ["currentStore", "storeMode", "isDiamondsPage"]),
        ...mapGetters("willyou", ["findJewelerLink", "wishlistLink", "getSemiAddress"]),
        isStoreMode() {
            return this.storeMode === true && this.isDiamondsPage === false;
        },
        getCurrentStoreUrl() {
            return !this.storeMode ? `/jewelers/${this.currentStore.url}` : `/jewelers/${this.currentStore.url}/live-diamond-feed`;
        },
    },
    methods: {
        showWishlistEmptyAlertAction() {
            return false;
        },
        handleWishlistBtn() {
            return this.wishlistLink !== this.$route.path || this.$route.name === "wishlist" ? null : this.showWishlistEmptyAlertAction();
        },
    },
};
</script>

<style lang="scss" scoped>
#navbar-top {
    .nav-rnd {
        background-color: #ffffff;
        padding-right: 24px;
        padding-left: 24px;
        border-bottom: solid 1px #ebebeb;
    }

    .nav-item-rnd {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 2px;
        color: rgba(0, 0, 0, 0.6);

        .nav-link {
            span {
                color: #767d98;
                vertical-align: middle;
                font-family: "Noto Sans KR", sans-serif;
            }
        }
    }
    .divider-left {
        border-left: 1px solid #767d98;
        padding-left: 24px;
        margin-left: 24px;
    }

    .navbar-brand-logo {
        height: 32px;
    }
}
</style>
