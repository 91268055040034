export const getParams = (data) => {
    return Object.keys(data)
        .map((key) => `${key}=${encodeURIComponent(data[key])}`)
        .join("&");
};

export const createNewFiltersValue = (filters, value) => {
    const isAdding = !(filters && filters.length ? filters.includes(value) : false);
    return isAdding ? filters.concat(value) : filters.filter.concat((s) => s !== value);
};

export const onlyNumbers = (string) => {
    return string.replace(/[^0-9]/g, "");
};

export const fromNumToPrice = (num) => {
    return `$${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const createDiamondUrl = (diamond) => {
    let diamondUrl = `${diamond.internal_lot}-${diamond.Weight}-carat-${diamond.Shape}-diamond-${diamond.Color}-${diamond.Clarity}`;
    return diamondUrl.toLowerCase();
};

export const firstCapitalLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getBeginningOfMonth = () => {
    let date = new Date();
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const getDiamondShapeProfessionalName = (shape) => {
    shape = shape !== null && shape !== "" && shape !== undefined ? shape.toLowerCase() : null;
    let shapesCaptions = [
        { shape: "round", title: "Round Cut Diamond" },
        { shape: "oval", title: "Oval Diamond" },
        { shape: "radiant", title: "Radiant Cut Diamond" },
        { shape: "cushion", title: "Cushion Cut Diamond" },
        { shape: "princess", title: "Princess Cut Diamond" },
        { shape: "asscher", title: "Asscher Cut Diamond" },
        { shape: "emerald", title: "Emerald Cut Diamond" },
        { shape: "pear", title: "Pear Shaped Diamond" },
        { shape: "marquise", title: "Marquise Diamond" },
        { shape: "heart", title: "Heart Shaped Diamond" },
    ];
    let result = shapesCaptions.find((obj) => shape === obj.shape);
    return result ? result.title : "";
};

export const makeValidUrl = (str) => {
    return str.toLowerCase().replace(/\s/g, "-");
};

export const convertHexToHTML = (str) => {
    return str.replace(/&#(?:x([\da-f]+)|(\d+));/gi, function (_, hex, dec) {
        return String.fromCharCode(dec || +("0x" + hex));
    });
};

// Takes diamond obj argument, returns full diamond details as a string
export const getDiamondsDetailsString = (diamond) => {
    return `${diamond.Weight} Carat ${getDiamondShapeProfessionalName(diamond.Shape)} ${diamond.Color}-${diamond.Clarity}`;
};

// returns query string without requested parameters
export const getQueryStrWithout = (params, withoutArr) => {
    return Object.keys(params)
        .map((element) => {
            return !withoutArr.includes(element) ? `${element}=${params[element]}` : null;
        })
        .filter((element) => {
            return element;
        })
        .join("&");
};

// returns day index as a string
export const getDayStr = (dayI) => {
    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return weekDays.length > dayI ? weekDays[dayI] : null;
};

// returns month index as a string
export const getMonthStr = (monthI) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months.length > monthI ? months[monthI] : null;
};

export const htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
};
