<template>
    <div id="fullstory"></div>
</template>

<script>
import Vue from "vue";
import * as FullStory from "@fullstory/browser";

export default {
    name: "fullstory",
    mounted() {
        setTimeout(() => {
            FullStory.init({ orgId: "Y15E4" });
            Vue.prototype.$FullStory = FullStory;
        }, 3500);
    },
};
</script>
