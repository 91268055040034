<template>
    <div id="mobile" class="d-lg-none">
        <nav class="navbar nav-rnd navbar-expand-lg">
            <router-link class="navbar-brand" to="/">
                <img alt="B" src="../../../assets/images/navbar/willyou.svg" class="navbar-brand-logo" />
            </router-link>
            <button
                ref="hamburger-btn"
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#mobile-nav-collapse"
                aria-controls="mobile-nav-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="changeCollapseStatus">
                <span class="navbar-toggler-icon">
                    <img v-show="!isNavbarCollapseOpen" alt="Menu" src="../../../assets/images/icons/menu.svg" class="navbar-regular-logo" />
                    <img v-show="isNavbarCollapseOpen" alt="X" src="../../../assets/images/icons/close.svg" class="navbar-regular-logo" />
                </span>
            </button>
            <div class="collapse navbar-collapse" id="mobile-nav-collapse">
                <div class="flex-center p-5">
                    <ul class="nav flex-column">
                        <li class="nav-item py-4">
                            <router-link
                                :to="{ name: 'dashboard' }"
                                :class="{ 'nav-link-rnd-selected': $route.name === 'dashboard' }"
                                class="nav-link nav-link-rnd text-uppercase"
                                @click.native="onLinkClick"
                                >Main
                            </router-link>
                        </li>
                        <li class="nav-item py-4">
                            <router-link
                                :to="{ name: 'about-store' }"
                                :class="{ 'nav-link-rnd-selected': $route.name === 'about-store' }"
                                class="nav-link nav-link-rnd text-uppercase"
                                @click.native="onLinkClick"
                                >About The Store
                            </router-link>
                        </li>
                        <li class="nav-item py-4">
                            <router-link
                                :to="{ name: 'details' }"
                                :class="{ 'nav-link-rnd-selected': $route.name === 'details' }"
                                class="nav-link nav-link-rnd text-uppercase"
                                @click.native="onLinkClick"
                                >Store Details
                            </router-link>
                        </li>
                        <li class="nav-item py-4">
                            <router-link
                                :to="{ name: 'diamond-markup' }"
                                :class="{ 'nav-link-rnd-selected': $route.name === 'diamond-markup' }"
                                class="nav-link nav-link-rnd text-uppercase"
                                @click.native="onLinkClick"
                                >Diamond Markup
                            </router-link>
                        </li>
                        <li class="nav-item py-4">
                            <router-link
                                to="/dashboard/ldf"
                                :class="{ 'nav-link-rnd-selected': $route.name === 'ldf' }"
                                class="nav-link nav-link-rnd text-uppercase"
                                @click.native="onLinkClick"
                                >LIVE DIAMOND FEED
                            </router-link>
                        </li>
                        <li class="nav-item py-4">
                            <router-link
                                to="/"
                                :class="{ 'nav-link-rnd-selected': $route.name === 'help' }"
                                class="nav-link nav-link-rnd small-item"
                                @click.native="onLinkClick"
                                >Help
                            </router-link>
                        </li>
                        <li class="nav-item py-4">
                            <router-link
                                to="/"
                                :class="{ 'nav-link-rnd-selected': $route.name === 'settings' }"
                                class="nav-link nav-link-rnd small-item"
                                @click.native="onLinkClick"
                                >Settings
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    name: "mobile",
    computed: {
        ...mapState("willyou", ["isNavbarCollapseOpen", "currentStore"]),
        ...mapState("willyou", ["isNavbarCollapseOpen", "currentStore"]),
        ...mapGetters("willyou", ["startWithRingLink", "startWithDiamondLink", "wishlistLink"]),
        getCompanyName() {
            return this.currentStore && this.$route.name === "home-store" ? this.currentStore.name : "";
        },
    },
    methods: {
        ...mapActions("willyou", {
            openNavbarCollapse: "openNavbarCollapse",
            closeNavbarCollapse: "closeNavbarCollapse",
        }),
        changeCollapseStatus() {
            this.isNavbarCollapseOpen ? this.closeNavbarCollapse() : this.openNavbarCollapse();
        },
        onLinkClick() {
            this.$refs["hamburger-btn"].click();
        },
    },
};
</script>

<style lang="scss" scoped>
#mobile {
    .nav-rnd {
        position: absolute;
        right: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.9);

        .mobile-nav-title {
            font-family: "Noto Sans", sans-serif;
        }

        .navbar-brand-logo {
            height: 32px;
        }

        .no-text {
            height: 40px;
        }

        .nav-item {
            text-align: center;
            border-bottom: 1px solid #fafafa;
            .nav-link-rnd {
                font-family: "Noto Sans", sans-serif;
                color: #333333;
            }
            .small-item {
                color: #4a4a4a;
            }
        }
        .nav-link-rnd:hover {
            color: #dc2b4a;
        }

        .nav-link-rnd:focus,
        .nav-link-rnd:active {
            color: #fd8498 !important;
        }
    }
}
</style>
