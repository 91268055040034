// import Home from './views/home/Home.vue';
// import Login from "./views/authentication/login/Login";
// import Details from "./views/details/Details";
// import DiamondMarkup from "./views/diamond-markup/DiamondMarkup";
// import AboutStore from "./views/about-store/AboutStore";
// import OnBoarding from "./views/onboarding/OnBoarding";
// import LeadManagement from "./views/lead-management/LeadManagement";
// import CrmLogin from "./views/authentication/CrmLogin";
// import ChangePassword from "./views/changePassword/ChangePassword";
// import References from "./views/references/References";
// import ForgotPassword from "./views/authentication/forgot-password/ForgotPassword";
// import Ldf from "./views/ldf/Ldf";

const layout = "dashboard-layout";
const needsAuth = true;
const guestOnly = true;

const dashboardRouter = [
    // {
    //     path: '/login',
    //     component: () =>
    //         import ("./views/authentication/login/Login.vue"),
    //     name: 'login',
    //     meta: { layout, guestOnly },
    // },
    // {
    //     path: '/ldf/login',
    //     component: () =>
    //         import ("./views/authentication/login/Login.vue"),
    //     name: 'ldf-login',
    //     props: (route) => ({
    //         routeProps: {
    //             isStoreMode: true,
    //         }
    //     }),
    //     meta: { layout, guestOnly },
    // }, {
    //     path: '/ldf/forgot-password',
    //     component: () =>
    //         import ("./views/authentication/forgot-password/ForgotPassword.vue"),
    //     name: 'ldf-forgot-password',
    //     props: (route) => ({
    //         routeProps: {
    //             isStoreMode: true,
    //         }
    //     }),
    //     meta: { layout, guestOnly },
    // }, {
    //     path: '/crmlogin',
    //     component: () =>
    //         import ("./views/authentication/CrmLogin.vue"),
    //     name: 'crmlogin',
    //     props: (route) => ({
    //         routeProps: {
    //             singleUseToken: route.query.singleUseToken,
    //             isForgotPassword: false,
    //         }
    //     }),
    // }, {
    //     path: '/forget-password-return',
    //     component: () =>
    //         import ("./views/authentication/CrmLogin.vue"),
    //     name: 'forget-password-return',
    //     props: (route) => ({
    //         routeProps: {
    //             singleUseToken: route.query.singleUseToken,
    //             isForgotPassword: true,
    //         }
    //     }),
    // },
    {
        path: "/crmlogin",
        component: () => import("./views/authentication/CrmLogin.vue"),
        name: "crmlogin",
        props: (route) => ({
            routeProps: {
                singleUseToken: route.query.singleUseToken,
                isForgotPassword: false,
            },
        }),
    },
    {
        path: "/register/:userID",
        component: () => import("./views/authentication/register/Register.vue"),
        name: "register-uid",
        props: (route) => ({
            routeProps: {
                userID: route.params.userID,
            },
        }),
        meta: { layout, guestOnly },
    },
    {
        path: "/register",
        component: () => import("./views/authentication/register/Register.vue"),
        name: "register",
        meta: { layout, guestOnly },
    },
    {
        path: "/ldf",
        component: () => import("./views/authentication/register/Register.vue"),
        name: "ldf-register",
        props: (route) => ({
            routeProps: {
                isStoreMode: true,
            },
        }),
        meta: { layout, guestOnly },
    },
    {
        path: "/ldf/:userID",
        component: () => import("./views/authentication/register/Register.vue"),
        name: "ldf-register-uid",
        props: (route) => ({
            routeProps: {
                isStoreMode: true,
                userID: route.params.userID,
            },
        }),
        meta: { layout, guestOnly },
    },
    // {
    //     path: '/forgot-password',
    //     component: () =>
    //         import ("./views/authentication/forgot-password/ForgotPassword.vue"),
    //     name: 'forgot-password',
    //     meta: { layout, guestOnly },
    // },
    {
        path: "/dashboard/details",
        component: () => import("./views/details/Details.vue"),
        name: "details",
        meta: { layout, needsAuth },
    },
    {
        path: "/dashboard/diamond-markup",
        component: () => import("./views/diamond-markup/DiamondMarkup.vue"),
        name: "diamond-markup",
        meta: { layout, needsAuth },
    },
    {
        path: "/dashboard/about-store",
        component: () => import("./views/about-store/AboutStore.vue"),
        name: "about-store",
        meta: { layout, needsAuth },
    },
    {
        path: "/dashboard/lead-management",
        component: () => import("./views/lead-management/LeadManagement.vue"),
        name: "lead-management",
        meta: { layout, needsAuth },
    },
    {
        path: "/dashboard/ldf",
        component: () => import("./views/ldf/Ldf.vue"),
        name: "ldf",
        meta: { layout, needsAuth },
    },
    // {
    //     path: '/dashboard/change-password',
    //     component: () =>
    //         import ('./views/changePassword/ChangePassword.vue'),
    //     name: 'change-password',
    //     meta: { layout, needsAuth },
    // }, {
    //     path: '/dashboard/reset-password',
    //     component: () =>
    //         import ('./views/changePassword/ChangePassword.vue'),
    //     name: 'change-password-reset',
    //     props: () => ({
    //         routeProps: {
    //             isResetPassword: true,
    //         }
    //     }),
    //     meta: { layout, needsAuth },
    // }, {
    //     path: '/dashboard/references',
    //     component: () =>
    //         import ('./views/references/References.vue'),
    //     name: 'references',
    //     meta: { layout, needsAuth },
    // }, {
    //     path: '/dashboard',
    //     component: () =>
    //         import ('./views/home/Home.vue'),
    //     name: 'dashboard',
    //     meta: { layout, needsAuth },
    //     children: [{
    //         path: 'on-boarding',
    //         component: () =>
    //             import ('./views/onboarding/OnBoarding.vue'),
    //         name: 'on-boarding',
    //         meta: { layout, needsAuth },
    //     }, ],
    // },
];

export default dashboardRouter;
