import axios from "axios";
import localforage from "localforage";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import uniq from "lodash/uniq";
import { getParams, createNewFiltersValue, getBeginningOfMonth, makeValidUrl } from "./helpers";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const willyou = {
    namespaced: true,
    state: {
        isNavbarCollapseOpen: false,
        currentPath: "/",
        currentPage: "home",
        baseApiUrl: "/api",
        // baseApiUrl: "http://localhost:8000/api",
        baseBlogUrl: "https://blog.willyou.net/wp-json/wp/v2",
        laravelWpBaseAPI: "https://blog.willyou.net/wp_laravel/index.php/api",
        baseApiUrlDemo: "/demo",
        states: [],
        latestPosts: [],
        stateCitiesByStores: null,
        currentStore: null,
        specialities: [],
        storesAccurate: [],
        storesRadius: [],
        storesLocation: {},
        browserLocation: null,
        loadReCaptcha: true,
        isLoading: false,
        rings: [],
        diamonds: [],
        currentDiamond: null,
        currentRing: null,
        currentArticle: null,
        currentArticleMeta: null,
        categoriesToShoInARow: window.innerWidth > 991 ? 4 : 2,
        wishlist: [],
        suggestions: [],
        ringsFilterStatus: {
            ringShapes: [],
            diamShapes: [],
            ringColors: [],
        },
        totalPagesToShow: 1,
        GIAtotalPagesToShow: 0,
        GIAtotalResultsToShow: 1,
        GIAelemetsInPage: 1,
        storeMode: false,
        isDiamondsPage: false,
        iframeMode: false,
        articles: [],
        articlesCategories: [],
        blogSpecialCategoryArticles: [],
        blogUsers: [],
        blogTotalPages: 1,
        blogTotalArticles: 0,
        switches: {
            blogCategories: false,
        },
        lastImpressions: [],
        GIADiamonds: [],
        GIACertificates: [],
        popup_fp: "",
        blogTimeoutId: 0,
        blogTimeoutCounter: 0,
    },
    mutations: {
        SET_NAVBAR_COLLAPSE_STATUS(state, newStatus) {
            state.isNavbarCollapseOpen = newStatus;
        },
        SET_CURRENT_PATH(state, newPath) {
            state.currentPath = newPath;
        },
        SET_CURRENT_PAGE(state, newPage) {
            state.currentPage = newPage;
        },
        SET_STATES_RATE(state, newStatesRateArray) {
            state.states = newStatesRateArray;
        },
        SET_LATEST_POSTS(state, latestPosts) {
            state.latestPosts = latestPosts;
        },
        SET_SPECIALITIES(state, newSpecialitiesArray) {
            state.specialities = newSpecialitiesArray;
        },
        SET_STORES_ACCURATE(state, newStoresArray) {
            state.storesAccurate = newStoresArray;
        },
        SET_STORES_RADIUS(state, newStoresArray) {
            state.storesRadius = newStoresArray;
        },
        SET_CURRENT_STORE(state, newStoreObject) {
            newStoreObject.onlyLead ? (newStoreObject.images.logo = null) : null;
            state.currentStore = newStoreObject;
        },
        SET_BROWSER_LOCATION(state, location) {
            state.browserLocation = location;
        },
        SET_LOAD_RECAPTCHA(state, boolValue) {
            state.loadReCaptcha = boolValue;
        },
        SET_LOADING(state, boolValue) {
            state.isLoading = boolValue;
        },
        SET_RINGS(state, rings) {
            state.rings = rings;
        },
        SET_DIAMONDS(state, diamonds) {
            state.diamonds = diamonds;
        },
        SET_CATEGORIES_TO_SHOW_IN_A_ROW(state, number) {
            state.categoriesToShoInARow = number;
        },
        SET_RESTORE_WISHLIST(state, products) {
            state.wishlist = products;
        },
        SET_RESET_WISHLIST(state) {
            state.wishlist = [];
        },
        SET_ADD_PRODUCT_TO_WISHLIST(state, product) {
            state.wishlist = state.wishlist.concat(product);
        },
        SET_REMOVE_PRODUCT_TO_WISHLIST(state, productId) {
            state.wishlist = state.wishlist.filter((p) => p.internalID !== productId);
        },
        SET_CURRENT_DIAMOND(state, diamond) {
            state.currentDiamond = diamond;
        },
        SET_CURRENT_RING(state, ring) {
            state.currentRing = ring;
        },
        SET_CURRENT_ARTICLE(state, article) {
            state.currentArticle = article;
        },
        SET_CURRENT_ARTICLE_META(state, meta) {
            state.currentArticleMeta = meta;
        },
        SET_SUGGESTIONS(state, suggestions) {
            state.suggestions = suggestions;
        },
        SET_RINGS_FILTER_STATUS(state, status) {
            state.ringsFilterStatus = status;
        },
        TOTAL_PAGES_TO_SHOW(state, pages) {
            state.totalPagesToShow = pages;
        },
        GIA_TOTAL_PAGES_TO_SHOW(state, pages) {
            state.GIAtotalPagesToShow = pages;
        },
        GIA_TOTAL_RESULTS_TO_SHOW(state, results) {
            state.GIAtotalResultsToShow = results;
        },
        GIA_ELEMENTS_IN_PAGE(state, elements) {
            state.GIAelementsInPage = elements;
        },
        SET_STORES_LOCATION(state, data) {
            state.storesLocation = data;
        },
        SET_STORE_MODE(state, data) {
            state.storeMode = data;
        },
        SET_IS_DIAMONDS_PAGE(state, data) {
            state.isDiamondsPage = data;
        },
        ACTIVATE_IFRAME_MODE(state) {
            state.iframeMode = true;
        },
        SET_STATE_STORES(state, data) {
            state.stateCitiesByStores = data;
        },
        SET_ARTICLES(state, acrticles) {
            state.articles = acrticles;
        },
        SET_ARTICLES_CATEGORIES(state, articlesCategories) {
            state.articlesCategories = articlesCategories;
        },
        SET_BLOG_USERS(state, blogUsers) {
            state.blogUsers = blogUsers;
        },
        SET_BLOG_TOTAL_PAGES(state, totalPages) {
            state.blogTotalPages = totalPages;
        },
        SET_BLOG_TOTAL_ARTICLES(state, totalArticles) {
            state.blogTotalArticles = totalArticles;
        },
        SET_BLOG_SPECIAL_CAT_ARTICLES(state, specialCatArticls) {
            state.blogSpecialCategoryArticles = specialCatArticls;
        },
        SET_SWITCH(state, switchObject) {
            state.switches[switchObject.switchName] = switchObject.switchValue ? switchObject.switchValue : !state.switches[switchObject.switchName];
        },
        SET_LAST_IMPRESSIONS(state, impressionsObject) {
            state.lastImpressions = impressionsObject;
        },
        SET_GIA_DIAMONDS(state, GIADiamonds) {
            state.GIADiamonds = GIADiamonds;
        },
        SET_GIA_CERTIFICATES(state, GIACertificates) {
            state.GIACertificates = GIACertificates;
        },
        SET_POPUP_FP(state, popup_fp) {
            state.popup_fp = popup_fp;
        },
        SET_BLOG_TIMEOUT_COUNTER(state, timeoutCounter) {
            state.blogTimeoutCounter = timeoutCounter;
        },
    },
    actions: {
        openNavbarCollapse({ commit }) {
            commit("SET_NAVBAR_COLLAPSE_STATUS", true);
        },
        closeNavbarCollapse({ commit }) {
            commit("SET_NAVBAR_COLLAPSE_STATUS", false);
        },
        initRequest({ state, commit }) {
            localforage.getItem("wishlist").then((wishlist) => {
                const wishlistToSet = wishlist && wishlist.length ? wishlist : [];
                // commit('SET_RESTORE_WISHLIST', wishlistToSet);
            });
            axios.get(`${state.baseApiUrl}/init`).then((response) => {
                const token = response.data.token;
                const states = response.data.states.filter((state) => state.shortName);
                const specialities = response.data.specialities;
                const rings = response.data.rings;
                const latestPosts = response.data.latestPosts;

                const setUpToken = (token) => {
                    localforage.setItem("token", token);
                    axios.defaults.headers.common["Authorization"] = "Bearer" + token;
                };
                const commitIfValid = (mutationName, value) => {
                    value && value.length ? commit(mutationName, value) : null;
                };
                token ? setUpToken(token) : null;
                commitIfValid("SET_STATES_RATE", states);
                commitIfValid("SET_SPECIALITIES", specialities);
                commitIfValid("SET_RINGS", rings);
                commitIfValid("SET_LATEST_POSTS", latestPosts);
            });
        },
        setCurrentPage({ commit }, page) {
            commit("SET_CURRENT_PAGE", page);
        },
        setCurrentPath({ commit }, path) {
            commit("SET_CURRENT_PATH", path);
        },
        setIsDiamondsPage({ commit }, path) {
            commit("SET_IS_DIAMONDS_PAGE", true);
        },
        getStoreData({ state, commit }, { url, router, ldf = false }) {
            commit("SET_LOADING", true);
            let query = ldf === true ? "?ldf=true" : "";
            axios
                .get(`${state.baseApiUrl}/stores/${url}${query}`)
                .then((response) => {
                    commit("SET_LOADING", false);
                    const store =
                        response && response.data && response.data.store && typeof response.data.store.length === "undefined" && !response.data.error
                            ? response.data.store
                            : null;
                    if (store !== null) {
                        commit("SET_CURRENT_STORE", store);
                    } else {
                        router.replace({ path: "/page-not-found" });
                    }
                })
                .catch(() => {
                    commit("SET_LOADING", false);
                    router.replace({ path: "/page-not-found" });
                });
        },
        removeStoreData({ commit }) {
            commit("SET_CURRENT_STORE", {});
        },
        getStoresByCityState({ state, rootState, commit }, cityState = { city: null, state: null }) {
            if (cityState.city && cityState.state) {
                var reqCity = makeValidUrl(cityState.city);
                var reqState = makeValidUrl(cityState.state);
                axios
                    .get(`${state.baseApiUrl}/stores?city=${reqCity}&shortname=${reqState}`)
                    .then((response) => {
                        const storesAccurate = response.data && response.data.accurate ? response.data.accurate : [];
                        const storesRadius = response.data && response.data.radius ? response.data.radius : [];
                        const storesLocation = response.data && response.data.location ? response.data.location : null;
                        storesAccurate && storesAccurate.length ? commit("SET_STORES_ACCURATE", storesAccurate) : null;
                        storesRadius && storesRadius.length ? commit("SET_STORES_RADIUS", storesRadius) : null;
                        storesLocation ? commit("SET_STORES_LOCATION", storesLocation) : null;
                    })
                    .catch(() => {
                        commit("SET_LOADING", false);
                    });
            }
        },
        getStores({ state, rootState, commit }, router) {
            commit("SET_LOADING", true);
            commit("SET_STORES_ACCURATE", []);
            commit("SET_STORES_RADIUS", []);
            const createUrl = () => {
                switch (rootState.route.name) {
                    case "stores-search-results-by-city":
                        const city = rootState.route.params && rootState.route.params.city ? rootState.route.params.city : null;
                        return `?city=${city}&shortname=${rootState.route.params.stateName}`;
                    case "stores-search-results-by-location":
                        const zipcode = rootState.route.query && rootState.route.query.zipcode ? rootState.route.query.zipcode : null;
                        const location = rootState.route.query && rootState.route.query.location ? rootState.route.query.location : null;
                        return location ? `?location=${location}` : `?zipcode=${zipcode}`;
                    default:
                        return `?zipcode=50021`;
                }
            };
            axios
                .get(`${state.baseApiUrl}/stores/${createUrl()}`)
                .then((response) => {
                    commit("SET_LOADING", false);
                    if (response.data && response.data.city && response.data.state) {
                        return router.replace({
                            name: "stores-search-results-by-city",
                            params: {
                                city: response.data.city,
                                stateName: response.data.state,
                            },
                        });
                    }
                    const storesAccurate = response.data && response.data.accurate ? response.data.accurate : [];
                    const storesRadius = response.data && response.data.radius ? response.data.radius : [];
                    const storesLocation = response.data && response.data.location ? response.data.location : null;

                    if (storesLocation) storesLocation.citiesDistances = response.data && response.data.nearestCities ? response.data.nearestCities : null;
                    if ((storesLocation === null || storesLocation.isActive !== "true") && typeof rootState.route.query.zipcode === "undefined") {
                        rootState.route.params.stateName
                            ? router.replace({
                                  path: `/jewelry-stores/${rootState.route.params.stateName}`,
                              })
                            : router.replace({ path: "/page-not-found" });
                    }
                    const sendToStoreLocatorIfNeeded = () =>
                        rootState.route.name === "stores-search-results-by-location"
                            ? router.replace({
                                  name: "find-a-jeweler-by-state",
                              })
                            : null;
                    const openCaptcha = () => {
                        commit("SET_LOAD_RECAPTCHA", true);
                    };
                    const commitIfValid = () => {
                        storesAccurate && storesAccurate.length ? commit("SET_STORES_ACCURATE", storesAccurate) : sendToStoreLocatorIfNeeded();
                        storesRadius && storesRadius.length ? commit("SET_STORES_RADIUS", storesRadius) : null;
                        storesLocation ? commit("SET_STORES_LOCATION", storesLocation) : null;
                        !response.data.isCaptchaNeeded ? commit("SET_LOAD_RECAPTCHA", false) : null;
                    };
                    return response.data ? commitIfValid() : null;
                    // return response.data && !response.data.isCaptchaNeeded
                    //   ? commitIfValid()
                    //   : openCaptcha();
                })
                .catch(() => {
                    commit("SET_LOADING", false);
                });
        },
        replaceBrowserLocation({ commit }, location) {
            location ? commit("SET_BROWSER_LOCATION", `${location.lng},${location.lat}`) : null;
        },
        checkCaptcha({ state, commit }, token) {
            token
                ? axios
                      .get(`${state.baseApiUrl}/captcha?token=${token}`, {
                          withCredentials: true,
                      })
                      .then((response) => {
                          response.data.success ? commit("SET_LOAD_RECAPTCHA", false) : null;
                      })
                      .catch()
                : null;
        },
        getDiamonds({ state, rootState, commit }, filtersObject) {
            commit("SET_LOADING", true);
            commit("SET_DIAMONDS", []);
            const resultsPerPage = filtersObject && filtersObject.resultsPerPage ? filtersObject.resultsPerPage : 20;
            const fullObject = Object.assign({}, filtersObject, {
                storeUrl: rootState.route.params ? rootState.route.params.url : null,
            });
            const ldf = state.storeMode ? "ldf=true&" : "";
            let route = `${state.baseApiUrl}/diamonds?perPage=${resultsPerPage}&${ldf}${getParams(pickBy(fullObject, identity))}`;
            route =
                filtersObject && filtersObject.diamondsPage
                    ? `${state.baseApiUrl}/diamonds?ldf=true&storeUrl=diamonds&${getParams(pickBy(fullObject, identity))}`
                    : route;
            filtersObject && filtersObject.diamondsPage ? commit("SET_IS_DIAMONDS_PAGE", true) : null;
            axios
                .get(route, fullObject)
                .then((response) => {
                    commit("SET_LOADING", false);

                    const diamonds = response.data && response.data.diamonds ? response.data.diamonds : [];
                    const pages = response.data && response.data.totalPages ? response.data.totalPages : 1;
                    const openCaptcha = () => {
                        commit("SET_LOAD_RECAPTCHA", true);
                    };
                    const commitIfValid = () => {
                        commit("SET_DIAMONDS", diamonds);
                        pages ? commit("TOTAL_PAGES_TO_SHOW", pages) : null;
                        !response.data.isCaptchaNeeded ? commit("SET_LOAD_RECAPTCHA", false) : null;
                    };
                    return response.data ? commitIfValid() : null;
                    // return response.data && !response.data.isCaptchaNeeded
                    //   ? commitIfValid()
                    //   : openCaptcha();
                })
                .catch(() => {
                    commit("SET_LOADING", false);
                });
        },
        getDiamondProduct({ state, rootState, commit }, { diamondId, router }) {
            commit("SET_LOADING", true);
            const ldf = state.storeMode ? "ldf=true&" : "";
            const diamondsPageOrNull = state.isDiamondsPage ? "diamonds" : null;
            axios
                .get(
                    `${state.baseApiUrl}/diamonds/${diamondId}?${ldf}storeUrl=${
                        rootState.route.params && rootState.route.params.url ? rootState.route.params.url : diamondsPageOrNull
                    }`
                )
                .then((response) => {
                    response.data && response.data.diamond ? commit("SET_CURRENT_DIAMOND", response.data.diamond) : router.replace({ path: "/page-not-found" });
                    commit("SET_SUGGESTIONS", response.data.suggestions);
                    commit("SET_LOADING", false);
                })
                .catch(() => {
                    commit("SET_LOADING", false);
                });
        },
        getRingProduct({ state, commit }, url) {
            commit("SET_LOADING", true);
            !state.currentRing
                ? axios
                      .get(`${this.state.baseApiUrlDemo}/product/ring.json`)
                      .then((response) => {
                          commit("SET_CURRENT_RING", response.data.ring);
                          commit("SET_SUGGESTIONS", response.data.suggestions);
                          commit("SET_LOADING", false);
                      })
                      .catch((error) => {
                          commit("SET_LOADING", false);
                      })
                : commit("SET_LOADING", false);
        },
        replaceCategoriesToShowInARow({ commit }, number) {
            commit("SET_CATEGORIES_TO_SHOW_IN_A_ROW", number);
        },
        addProductToWishlist({ state, commit }, product) {
            const includeJewelerInProduct = Object.assign({}, product, {
                jewelerId: state.currentStore ? state.currentStore.internalID : null,
            });
            // commit('SET_RESET_WISHLIST');
            commit("SET_ADD_PRODUCT_TO_WISHLIST", includeJewelerInProduct);
            // localforage.setItem('wishlist', state.wishlist);
            // axios.get(`${this.state.baseApiUrl}/events`, {eventType: 'addProductToWishlist', productId: product.id});
        },
        removeProductFromWishlist({ state, commit }, productId) {
            commit("SET_REMOVE_PRODUCT_TO_WISHLIST", productId);
            localforage.setItem("wishlist", state.wishlist);
            // axios.get(`${this.state.baseApiUrl}/events`, {eventType: 'removeProductFromWishlist', productId: productId});
        },
        addDiamondToState({ commit }, diamond) {
            commit("SET_CURRENT_DIAMOND", diamond);
        },
        addRingToState({ commit }, ring) {
            commit("SET_CURRENT_RING", ring);
        },
        resetSuggestions({ commit }) {
            commit("SET_SUGGESTIONS", []);
        },
        selectRingShape({ state, commit }, shape) {
            const newFiltersStatus = {
                ringShapes: createNewFiltersValue(state.ringsFilterStatus.ringShapes, shape),
                diamShapes: state.ringsFilterStatus.diamShapes,
                ringColors: state.ringsFilterStatus.ringColors,
            };
            commit("SET_RINGS_FILTER_STATUS", newFiltersStatus);
        },
        selectDiamShape({ state, commit }, shape) {
            const newFiltersStatus = {
                ringShapes: state.ringsFilterStatus.ringShapes,
                diamShapes: createNewFiltersValue(state.ringsFilterStatus.diamShapes, shape),
                ringColors: state.ringsFilterStatus.ringColors,
            };
            commit("SET_RINGS_FILTER_STATUS", newFiltersStatus);
        },
        selectRingColor({ state, commit }, color) {
            const newFiltersStatus = {
                ringShapes: state.ringsFilterStatus.ringShapes,
                diamShapes: state.ringsFilterStatus.diamShapes,
                ringColors: createNewFiltersValue(state.ringsFilterStatus.ringColors, color),
            };
            commit("SET_RINGS_FILTER_STATUS", newFiltersStatus);
        },
        updateRingsFilter({ commit }, filtersStatus) {
            commit("SET_RINGS_FILTER_STATUS", filtersStatus);
        },
        activateStoreMode({ commit }) {
            commit("SET_STORE_MODE", true);
        },
        deactivateStoreMode({ commit }) {
            commit("SET_STORE_MODE", false);
        },
        iframeLayout({ commit }) {
            commit("ACTIVATE_IFRAME_MODE");
        },
        getBlogSpecialCategoryArticles({ state, commit }) {
            if (state.blogTimeoutCounter > 0 || state.blogSpecialCategoryArticles.length !== 0) return;
            axios.get(`${state.baseBlogUrl}/posts?per_page=100&categories=340&page=1`).then((response) => {
                commit("SET_BLOG_SPECIAL_CAT_ARTICLES", response.data);
            });
        },
        getArticles({ state, rootState, commit, dispatch }, payload = { filtersObject: null, router: null }) {
            commit("SET_ARTICLES", []);
            let filtersObject = payload.filtersObject;
            let router = payload.router;
            commit("SET_LOADING", true);
            commit("SET_BLOG_TIMEOUT_COUNTER", state.blogTimeoutCounter++);
            if (state.blogSpecialCategoryArticles.length === 0) dispatch("getBlogSpecialCategoryArticles");
            if (state.articlesCategories.length === 0) {
                dispatch("getArticlesCategories");
                const timeout = state.blogTimeoutCounter <= 2 ? 1200 : 7500;
                setTimeout(() => {
                    dispatch("getArticles", payload);
                }, timeout);
            } else {
                const fullObject = Object.assign({}, filtersObject, {
                    storeUrl: rootState.route.params ? rootState.route.params.url : null,
                });

                let currentPage = rootState.route.query.page ? rootState.route.query.page : 1;
                let routeSubcategory = rootState.route.params.pathMatch ? rootState.route.params.pathMatch.split("/") : null;
                let routeLastSubcategory = routeSubcategory ? routeSubcategory[routeSubcategory.length - 1] : null;
                let category = rootState.route.params.category ? state.articlesCategories.find((cat) => rootState.route.params.category === cat.slug) : 0;
                category = routeLastSubcategory ? state.articlesCategories.find((cat) => routeLastSubcategory === cat.slug) : category;
                if (payload.permalink && payload.articleCatrgory) {
                    // Article mode
                    category = state.articlesCategories.find((cat) => routeLastSubcategory === payload.articleCatrgory);
                }

                const currentCat =
                    rootState.route.path.split("/") && rootState.route.path.split("/")[rootState.route.path.split("/").length - 2] && !category
                        ? rootState.route.path.split("/")[rootState.route.path.split("/").length - 2]
                        : null;
                if ((typeof category === "undefined" || category === 0 || !category.id || !currentCat || rootState.route.path !== "/blog") && currentCat) {
                    category = state.articlesCategories.find((cat) => currentCat === cat.slug);
                }

                // if (typeof(category) === 'undefined') return router.replace({ path: '/page-not-found' });

                category = typeof category !== "undefined" && category !== 0 && category.id ? `&categories=${category.id}` : "";
                let perPage = payload.payload !== null && payload.payload.perPage ? payload.payload.perPage : 18;

                axios
                    .get(`${state.baseBlogUrl}/posts?per_page=${perPage}&page=${currentPage}${category}${getParams(pickBy(fullObject, identity))}`, fullObject)
                    .then((response) => {
                        commit("SET_LOADING", false);
                        let totalPages = response.headers && response.headers["x-wp-totalpages"] ? response.headers["x-wp-totalpages"] : 1;
                        let totalArticles = response.headers && response.headers["x-wp-total"] ? response.headers["x-wp-total"] : 0;

                        const addLinksToArticles = (articlesObj) =>
                            articlesObj.map((a) => {
                                a.availableAt = state.articlesCategories
                                    .filter((cat) => a.categories.indexOf(cat.id) > -1)
                                    .map((foundCat) => `/blog${foundCat.link.substr(33).slice(0, -1)}`);
                                return a;
                            });

                        commit("SET_BLOG_TOTAL_PAGES", parseInt(totalPages));
                        commit("SET_BLOG_TOTAL_ARTICLES", parseInt(totalArticles));
                        commit("SET_BLOG_TIMEOUT_COUNTER", 0);
                        const articles = response.data ? addLinksToArticles(response.data) : [];
                        const commitIfValid = () => {
                            articles && articles.length ? commit("SET_ARTICLES", articles) : null;
                        };
                        return articles ? commitIfValid() : null;
                    })
                    .catch((error) => {
                        error.response.data && error.response.data.code && error.response.data.code === "rest_post_invalid_page_number"
                            ? (rootState.route.query.page = 1)
                            : null;
                        dispatch("getArticles");
                        commit("SET_LOADING", false);
                    });
            }
        },

        getUsers({ state, commit }) {
            commit("SET_LOADING", true);
            axios
                .get(`${state.baseBlogUrl}/users?per_page=100`)
                .then((response) => {
                    commit("SET_LOADING", false);
                    const users = response.data
                        ? response.data.map((user) => ({
                              id: user.id,
                              name: user.name,
                              slug: user.slug,
                          }))
                        : [];
                    const commitIfValid = () => {
                        users && users.length ? commit("SET_BLOG_USERS", users) : null;
                    };
                    return users ? commitIfValid() : null;
                })
                .catch(() => {
                    commit("SET_LOADING", false);
                });
        },
        getArticlesCategories({ state, commit, dispatch }, page = 1) {
            if (!state.switches.blogCategories || page > 1) {
                commit("SET_LOADING", true);
                commit("SET_SWITCH", { switchName: "blogCategories" });
                axios
                    .get(`${state.baseBlogUrl}/categories?per_page=100&hide_empty=true&page=${page}`)
                    .then((response) => {
                        commit("SET_LOADING", false);
                        const articlesCategories = response.data
                            ? response.data.map((category) => ({
                                  id: category.id,
                                  name: category.name,
                                  slug: category.slug,
                                  parent: category.parent,
                                  link: category.link,
                                  yoast: category.yoast_head,
                              }))
                            : [];
                        const articlesCategoriesMerged = state.articlesCategories.concat(articlesCategories);
                        const commitIfValid = () => {
                            articlesCategories && articlesCategories.length ? commit("SET_ARTICLES_CATEGORIES", articlesCategoriesMerged) : null;
                        };
                        let totalPages = response.headers["X-WP-TotalPages"];
                        if (totalPages && totalPages > 1 && page < totalPages) dispatch("getArticlesCategories", page++);

                        return articlesCategories ? commitIfValid() : null;
                    })
                    .catch(() => {
                        commit("SET_LOADING", false);
                    });
            }
        },
        checkForRedirectedArticle({ state }, payload = {}) {
            if (!payload.permalink || !payload.permalink.length) window.location.href = "/page-not-found";
            axios
                .get(`${state.laravelWpBaseAPI}/get-new-slug/${payload.permalink}`)
                .then((response) => {
                    if (response && response.data && response.data.newUri) {
                        response.data.newUri = response.data.newUri.replace("og.willyou.net/", "");
                        window.location.href = `/blog/${response.data.newUri}`;
                    }
                })
                .catch((response) => {
                    if (response.response.status === 404) window.location.href = "/page-not-found";
                });
        },
        getArticle(
            { state, rootState, commit, dispatch },
            payload = {
                permalink: "",
                router: null,
            }
        ) {
            let permalink = payload.permalink;
            !permalink ? payload.router.replace({ path: "/page-not-found" }) : null;
            commit("SET_LOADING", true);
            if (state.articlesCategories.length === 0) {
                dispatch("getArticlesCategories");
                setTimeout(() => {
                    dispatch("getArticle", payload);
                }, 500);
            } else {
                axios
                    .get(`${state.baseBlogUrl}/posts?slug=${permalink.permalink}`)
                    .then((response) => {
                        if (response.data.length === 0 || window.location.href.includes("/category-articles")) {
                            // 1. Check if this article really does not exists. or maybe it is only a redirect.
                            dispatch("checkForRedirectedArticle", { ...payload.router, ...permalink });
                        } else {
                            const correctLinkCats = response.data[0].link.split("https://blog.willyou.net")[1].slice(0, -1);
                            const locationCats = window.location.pathname.split("/blog")[1];
                            if (correctLinkCats !== locationCats && correctLinkCats.split("category-articles").length <= 1) {
                                payload.router.push({ path: `/blog${correctLinkCats}` });
                            }
                            let article = response.data[0];
                            article.availableAt = state.articlesCategories
                                .filter((cat) => article.categories.indexOf(cat.id) > -1)
                                .map((foundCat) => `/blog${foundCat.link.substr(33).slice(0, -1)}`);
                            commit("SET_CURRENT_ARTICLE", response.data[0]);
                            commit("SET_CURRENT_ARTICLE_META", {
                                yoast_title: response.data[0].yoast_title,
                                yoast_meta: response.data[0].yoast_meta,
                                yoast_json_ld: response.data[0].yoast_json_ld,
                            });
                        }
                        commit("SET_LOADING", false);
                    })
                    .catch(() => {
                        commit("SET_LOADING", false);
                    });
            }
        },
        setLastImpressions({ state, commit }, lastImpressionsObject = []) {
            commit("SET_LAST_IMPRESSIONS", lastImpressionsObject);
        },

        getGIADiamonds({ state, commit }, filtersObject) {
            commit("SET_LOADING", true);
            commit("SET_GIA_DIAMONDS", []);
            commit("GIA_TOTAL_PAGES_TO_SHOW", 0);
            commit("SET_GIA_CERTIFICATES", []);
            axios
                .get(`${state.baseApiUrl}/gia/diamonds?${getParams(pickBy(filtersObject, identity))}`, filtersObject)
                .then((response) => {
                    const diamonds = response.data && response.data.diamonds ? response.data.diamonds : [];
                    const pages = response.data && response.data.lastPage ? response.data.lastPage : 1;
                    const results = response.data && response.data.total ? response.data.total : 0;
                    const elements = response.data && response.data.elementsInPage ? response.data.elementsInPage : 0;
                    const openCaptcha = () => {
                        commit("SET_LOAD_RECAPTCHA", true);
                    };
                    const commitIfValid = () => {
                        diamonds && diamonds.length ? commit("SET_GIA_DIAMONDS", diamonds) : null;
                        pages ? commit("GIA_TOTAL_PAGES_TO_SHOW", pages) : null;
                        pages ? commit("GIA_TOTAL_RESULTS_TO_SHOW", results) : null;
                        pages ? commit("GIA_ELEMENTS_IN_PAGE", elements) : null;
                        !response.data.isCaptchaNeeded ? commit("SET_LOAD_RECAPTCHA", false) : null;
                    };
                    commit("SET_LOADING", false);
                    return response.data && !response.data.isCaptchaNeeded ? commitIfValid() : openCaptcha();
                })
                .catch(() => {
                    commit("SET_LOADING", false);
                });
        },
        getGIACertificates({ state, commit }, filtersObject) {
            commit("SET_LOADING", true);
            commit("SET_GIA_DIAMONDS", []);
            commit("SET_GIA_CERTIFICATES", []);
            axios
                .get(`${state.baseApiUrl}/gia?${getParams(pickBy(filtersObject, identity))}`, filtersObject)
                .then((response) => {
                    commit("SET_LOADING", false);
                    const certificates = response.data && response.data.certificates ? response.data.certificates : [];
                    const openCaptcha = () => {
                        commit("SET_LOAD_RECAPTCHA", true);
                    };
                    const commitIfValid = () => {
                        certificates && certificates.length ? commit("SET_GIA_CERTIFICATES", certificates) : null;
                        !response.data.isCaptchaNeeded ? commit("SET_LOAD_RECAPTCHA", false) : null;
                    };
                    return response.data ? commitIfValid() : null;
                    // return response.data && !response.data.isCaptchaNeeded
                    //   ? commitIfValid()
                    //   : openCaptcha();
                })
                .catch(() => {
                    commit("SET_LOADING", false);
                });
        },
        getLivePopup({ state, commit }) {
            axios
                .post(`${state.baseApiUrl}/popup`, { _fp: true })
                .then((response) => {
                    const popup_fp = response && response.data ? response.data.popup : null;

                    commit("SET_POPUP_FP", popup_fp);
                    return true;
                })
                .catch(() => {});
        },
        updateLivePopup({ state }, data) {
            axios
                .put(`${state.baseApiUrl}/popup/${data.popup_fp}`, data)
                .then((response) => {
                    const success = response && response.data ? response.data.token : null;
                    return success;
                })
                .catch(() => {});
        },
    },
    getters: {
        findJewelerLink(state) {
            return state.browserLocation ? `/jewelry-stores/results?location=${state.browserLocation}` : "/jewelry-stores";
        },
        startWithDiamondLink(state) {
            if (state.currentStore && state.currentStore.url) {
                return `/jewelers/${state.currentStore.url}/diamonds`;
            } else {
                return state.browserLocation ? `/jewelry-stores/results?location=${state.browserLocation}` : "/jewelry-stores";
            }
        },
        startWithRingLink(state) {
            if (state.currentStore && state.currentStore.url) {
                return `/jewelers/${state.currentStore.url}/engagement-rings`;
            } else {
                return state.browserLocation ? `/jewelry-stores/results?location=${state.browserLocation}` : "/jewelry-stores";
            }
        },
        wishlistLink(state, getters, rootState) {
            return "";
        },
        currentStateName(state, getters, rootState) {
            const getName = () => {
                return state.states.find((s) => s.shortName.toLowerCase() === rootState.route.params.stateName)
                    ? state.states.find((s) => s.shortName.toLowerCase() === rootState.route.params.stateName).name
                    : "";
            };
            return rootState.route.name === "find-a-jeweler-by-city" ? getName() : "";
        },
        getCurrentRings(state, getters, rootState) {
            const isFineByParamters = (filtersArr, value) => {
                return filtersArr && filtersArr.length ? filtersArr.includes(value) : true;
            };
            return state.rings.length
                ? state.rings.filter((r) => {
                      return (
                          isFineByParamters(rootState.route.query.ringshapes, makeValidUrl(r.ringShape)) &&
                          isFineByParamters(rootState.route.query.diamshapes, makeValidUrl(r.diamShape)) &&
                          isFineByParamters(rootState.route.query.ringcolors, makeValidUrl(r.ringColor))
                      );
                  })
                : [];
        },
        getRingsFilterOptions(state, getters) {
            return {
                ringShapes: uniq(getters.getCurrentRings.map((r) => r.ringShape)),
                diamShapes: uniq(getters.getCurrentRings.map((r) => r.diamShape)),
                ringColors: uniq(getters.getCurrentRings.map((r) => r.ringColor)),
            };
        },
        getCurrentWishlist(state) {
            return state.wishlist && state.wishlist.length && state.currentStore
                ? state.wishlist.filter((product) => product.jewelerId === state.currentStore.internalID)
                : [];
        },
        chosenRing(state, getters) {
            return getters.getCurrentWishlist && getters.getCurrentWishlist.length ? !!getters.getCurrentWishlist.find((p) => p.ringColor) : false;
        },
        chosenDiamond(state, getters) {
            return getters.getCurrentWishlist && getters.getCurrentWishlist.length ? !!getters.getCurrentWishlist.find((p) => p.internal_lot) : false;
        },
        getSemiAddress(state, getters, rootState) {
            if (state.currentStore && state.currentStore.companyName) {
                return state.currentStore
                    ? `${state.currentStore.city ? state.currentStore.city : ""} ${state.currentStore.state && state.currentStore.state ? "," : ""} ${
                          state.currentStore.state ? state.currentStore.state : ""
                      }`
                    : "USA";
            } else if (state.storesAccurate && state.storesAccurate.length) {
                return state.storesAccurate[0]
                    ? `${state.storesAccurate[0].city ? state.storesAccurate[0].city : ""} ${
                          state.storesAccurate[0].state && state.storesAccurate[0].state ? "," : ""
                      }
                ${state.storesAccurate[0].state ? state.storesAccurate[0].state : ""}`
                    : "";
            } else if (rootState.route.params && rootState.route.params.city && rootState.route.params.stateName) {
                let city = rootState.route.params.city;
                return `${city.charAt(0).toUpperCase()}${city.slice(1)}, ${rootState.route.params.stateName.toUpperCase()}`;
            } else {
                return state.currentStore
                    ? `${state.currentStore.city ? state.currentStore.city : ""} ${state.currentStore.state && state.currentStore.state ? "," : ""}
                ${state.currentStore.state ? state.currentStore.state : ""}`
                    : "";
            }
        },
        getZipcode(state, getters, rootState) {
            return rootState.route.query.zipcode ? rootState.route.query.zipcode : "";
        },
        getJewelerName(state) {
            return state.currentStore && state.currentStore.companyName ? state.currentStore.companyName : "";
        },
        ringObject(state, getters, rootState) {
            return state.rings && state.rings.length && rootState.route.params
                ? state.rings.find((r) => makeValidUrl(r.meta.url) === rootState.route.params.ringId)
                : {};
        },
        getCurrentMeta(state, getters, rootState) {
            const metaByPages = {
                home: {
                    title: `Willyou.net - Engagement Rings Near Me`,
                    path: rootState.route.path,
                    description: `Willyou.net helps you find top-rated USA jewelers for your dream engagement ring. Explore, compare, and choose with real customer feedback.`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["Engagement Rings Near Me"],
                    shouldIndex: true,
                },
                "dmca-policy": {
                    title: `Willyou.net - Engagement Rings Near Me`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you - Bring you the best of both worlds - Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["Engagement Rings Near Me"],
                    shouldIndex: false,
                },
                "home-store": {
                    title: getters.getJewelerName
                        ? `${getters.getJewelerName}, Custom Engagement Rings in ${getters.getSemiAddress}`
                        : `Diamond Engagement Ring at a Jeweler Near Me`,
                    path: rootState.route.path,
                    description: `${
                        state.currentStore && state.currentStore.metaFields && state.currentStore.metaFields.metaDescription
                            ? state.currentStore.metaFields.metaDescription
                            : `Shop Online. Pay & Pick up at Your Local Jeweler`
                    }`,
                    imagePath: state.currentStore ? `/images/stores/${state.currentStore.internalID}-1.png` : `/images/design-your-own-ring.png`,
                    keywords: [state.currentStore && state.currentStore.metaFields ? state.currentStore.metaFields.metaKeywords : ""],
                    shouldIndex: true,
                },
                "find-a-jeweler-by-state": {
                    title: `Jewelry Store Near Me - Last Updated ${getBeginningOfMonth()}`,
                    path: rootState.route.path,
                    description: `Use the "Jewelry Store Near Me" store locator to find the best jewelry store around!`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["Jewelry Stores Near Me"],
                    shouldIndex: true,
                },
                "find-a-jeweler-by-city": {
                    title: `Jewelry Stores in ${getters.currentStateName}`,
                    path: rootState.route.path,
                    description: `Jewelry Store in ${getters.currentStateName} for Engagement Rings`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: [`Jewelry Stores ${getters.currentStateName}`],
                    shouldIndex: true,
                },
                "stores-search-results-by-location": {
                    title: `${state.storesAccurate.length || ""} Best Jewelry stores near your zip code ${getters.getZipcode}`,
                    path: rootState.route.path,
                    description: `Find jewelry stores in ${getters.getSemiAddress}, ${getters.getZipcode}. Search online and customize at a local ${getters.getSemiAddress} jewelry store.`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: [`Jewelry Stores ${getters.getSemiAddress}, ${getters.getZipcode}`],
                    shouldIndex: true,
                },
                "stores-search-results-by-city": {
                    title:
                        state.storesAccurate && state.storesAccurate.length > 0
                            ? `${state.storesAccurate.length || ""} Best Jewelry stores in ${getters.getSemiAddress} for Engagement Rings`
                            : `Jewelry Stores in  ${getters.getSemiAddress} for Engagement Rings`,
                    path: rootState.route.path,
                    description: `Choose a local jeweler from our directory to match you with the best jewelers in ${
                        getters.getSemiAddress
                    } - Last Updated ${getBeginningOfMonth()}`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: [`Best Jewelry Store ${getters.getSemiAddress} for Engagement Rings`],
                    shouldIndex: true,
                },
                diamonds: {
                    title: `${getters.getJewelerName}, ${getters.getSemiAddress} - GIA Diamonds`,
                    path: rootState.route.path,
                    description: `Shop Online. Pay & Pick up at Your Local Jeweler`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                rings: {
                    title: `${getters.getJewelerName}, ${getters.getSemiAddress} - Engagement Rings`,
                    path: rootState.route.path,
                    description: `Shop Online. Pay & Pick up at Your Local Jeweler`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                wishlist: {
                    title: `${getters.getJewelerName}, ${getters.getSemiAddress} - My wishlist`,
                    path: rootState.route.path,
                    description: `Shop Online. Pay & Pick up at Your Local Jeweler`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "product-diamond": {
                    title:
                        state.currentDiamond && state.currentDiamond.Shape
                            ? `${getters.getJewelerName} -${state.currentDiamond.Carat || ""} Carat, ${state.currentDiamond.Shape} Diamond, ${
                                  state.currentDiamond.Color
                              } Color, ${state.currentDiamond.Clarity} Clarity`
                            : `${getters.getJewelerName} - Diamond details`,
                    path: rootState.route.path,
                    description: `Shop Online. Pay & Pick up at Your Local Jeweler`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "jeweler-schedule-popup": {
                    title:
                        state.currentDiamond && state.currentDiamond.Shape
                            ? `${getters.getJewelerName} -${state.currentDiamond.Carat || ""} Carat, ${state.currentDiamond.Shape} Diamond, ${
                                  state.currentDiamond.Color
                              } Color, ${state.currentDiamond.Clarity} Clarity`
                            : `${getters.getJewelerName} - Diamond details`,
                    path: rootState.route.path,
                    description: `Shop Online. Pay & Pick up at Your Local Jeweler`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "product-ring": {
                    title:
                        getters.ringObject && getters.ringObject.ringShape
                            ? getters.ringObject.meta.title.replace(/{companyName}/g, getters.getJewelerName).replace(/{cityState}/g, getters.getSemiAddress)
                            : `${getters.getJewelerName} - Ring details`,
                    path: rootState.route.path,
                    description:
                        getters.ringObject && getters.ringObject.meta && getters.ringObject.meta.description
                            ? getters.ringObject.meta.description
                                  .replace(/{companyName}/g, getters.getJewelerName)
                                  .replace(/{cityState}/g, getters.getSemiAddress)
                            : `Shop Online. Pay & Pick up at Your Local Jeweler`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords:
                        getters.ringObject && getters.ringObject.meta && getters.ringObject.meta.keywords
                            ? getters.ringObject.meta.keywords.replace(/{companyName}/g, getters.getJewelerName).replace(/{cityState}/g, getters.getSemiAddress)
                            : ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "engagement-rings-search-product-ring": {
                    title:
                        getters.ringObject && getters.ringObject.ringShape
                            ? getters.ringObject.meta.title
                                  .replace(/{companyName}/g, getters.getJewelerName)
                                  .replace(/{cityState}/g, getters.getSemiAddress)
                                  .replace(/-/g, "")
                            : `${getters.getJewelerName} - Ring details`,
                    path: rootState.route.path,
                    description:
                        getters.ringObject && getters.ringObject.meta && getters.ringObject.meta.description
                            ? getters.ringObject.meta.description
                                  .replace(/{companyName}/g, getters.getJewelerName)
                                  .replace(/{cityState}/g, getters.getSemiAddress)
                            : `Shop Online. Pay & Pick up at Your Local Jeweler`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords:
                        getters.ringObject && getters.ringObject.meta && getters.ringObject.meta.keywords
                            ? getters.ringObject.meta.keywords.replace(/{companyName}/g, getters.getJewelerName).replace(/{cityState}/g, getters.getSemiAddress)
                            : ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: true,
                },
                "engagement-rings": {
                    title: `Best Place to Buy an Engagement Ring`,
                    path: rootState.route.path,
                    description: `The best place to buy an engagement ring and diamond ring, online or in a jewelry store near you.`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["best place to buy engagement ring"],
                    shouldIndex: true,
                },
                "minisite-user": {
                    title: `Jewelry Stores Near Me - willyou.net`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                minisite: {
                    title: `Jewelry Stores Near Me - willyou.net`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "more-traffic-user": {
                    title: `Jewelry Stores Near Me - willyou.net`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "more-traffic": {
                    title: `Jewelry Stores Near Me - willyou.net`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "under-construction": {
                    title: `Jewelry Stores Near Me - willyou.net`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "live-showcase": {
                    title: `${getters.getJewelerName}, ${getters.getSemiAddress}: Diamond Engagement Ring at a Jeweler Near Me`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false, // SEO requirement was to eliminate the robots tag completely from this page. So we handled it from *within* the component itself(!).
                },
                "diamond-popup": {
                    title: `${getters.getJewelerName}, ${getters.getSemiAddress}: Diamond Engagement Ring at a Jeweler Near Me`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "schedule-popup": {
                    title: `${getters.getJewelerName}, ${getters.getSemiAddress}: Diamond Engagement Ring at a Jeweler Near Me`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                about: {
                    title: `Willyou.net - About Us`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keyWords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "page-not-found": {
                    title: `Page not found - willyou.net`,
                    path: rootState.route.path,
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                    shouldIndex: false,
                },
                "engagement-rings-search": {
                    title: `ENGAGEMENT RINGS`,
                    path: rootState.route.path,
                    description: `Shop with guidance, education, and hundreds of handcrafted engagement settings in various popular styles, including solitaire, pave, 3 stone, twisted, and halo.`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["Engagement rings"],
                    shouldIndex: true,
                },
                "popular-engagement-rings-iframe": {
                    title: `ENGAGEMENT RINGS`,
                    path: rootState.route.path,
                    shouldIndex: true,
                    canonical: "https://willyou.net/engagement-rings",
                },
                "diamonds-page": {
                    title: `Diamonds for Engagement Rings`,
                    path: rootState.route.path,
                    description: `Diamonds for Engagement Rings | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["diamonds"],
                    shouldIndex: true,
                },
                "diamonds-page-diamond-popup": {
                    title: `Willyou.net - `,
                    path: rootState.route.path,
                    // description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you. Bring you the best of both worlds. | Willyou.net`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["diamonds"],
                    shouldIndex: false,
                },
                blog: {
                    title: `Blog - Last update ${getBeginningOfMonth()}`,
                    path: rootState.route.path,
                    description: `Willyou.net's Blog and Newsroom is home to articles, news, and announcements from our US community of jewelry stores and jewelers.`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["Blog", "Jewelry", "Jeweler", "Jewelry store", "Diamonds"],
                    shouldIndex: true,
                },
                "blog-article": state.currentArticleMeta
                    ? {
                          title: state.currentArticleMeta.yoast_title,
                          path: rootState.route.path,
                          description: state.currentArticleMeta.yoast_meta.find((meta) => meta.name === "description").content,
                          imagePath:
                              state.currentArticleMeta &&
                              state.currentArticleMeta.yoast_meta &&
                              state.currentArticleMeta.yoast_meta.find((meta) => meta.name === "og:image")
                                  ? state.currentArticleMeta.yoast_meta.find((meta) => meta.name === "og:image").content
                                  : "",
                          keyWords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
                          shouldIndex: true,
                          metaList: state.currentArticleMeta.yoast_meta,
                      }
                    : {},

                // GIA PAGES

                "gia-report-certificate-check": {
                    title: `GIA Report Certificate Check & Lookup for GIA Diamond Grading`,
                    path: rootState.route.path,
                    description: `GIA diamond report states the qualities of the diamond. Learn how to read a GIA diamond certificate correctly and check reports in the GIA Diamonds - Grade & Score Check`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["GIA certificate", "GIA Diamond", "Diamond grading"],
                    shouldIndex: true,
                },

                "certificate-shape": {
                    title: `GIA Report Certificate Check & Lookup for GIA Diamond Grading`,
                    path: rootState.route.path,
                    description: `GIA diamond report states the qualities of the diamond. Learn how to read a GIA diamond certificate correctly and check reports in the GIA Diamonds - Grade & Score Check`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["GIA certificate", "GIA Diamond", "Diamond grading"],
                    shouldIndex: true,
                },

                "gia-results": {
                    title: `GIA Report Certificate Check & Lookup for GIA Diamond Grading`,
                    path: rootState.route.path,
                    description: `GIA diamond report states the qualities of the diamond. Learn how to read a GIA diamond certificate correctly and check reports in the GIA Diamonds - Grade & Score Check`,
                    imagePath: `/images/design-your-own-ring.png`,
                    keywords: ["GIA certificate", "GIA Diamond", "Diamond grading"],
                    shouldIndex: true,
                },

                // DASHBOARD PAGES

                register: {
                    shouldIndex: true,
                    canonical: "https://willyou.net",
                },
                "ldf-register": {
                    description: `Experience the online diamond engagement ring variety with the peace of mind of shopping in a jewelry store near you - Bring you the best of both worlds - Willyou.net`,
                    shouldIndex: true,
                    canonical: "https://willyou.net",
                },
            };
            const currentMeta = metaByPages[rootState.route.name] ? metaByPages[rootState.route.name] : metaByPages["home"];
            return {
                title: currentMeta.title,
                meta: [
                    {
                        hid: "viewport",
                        content: "width=device-width, viewport-fit=cover, initial-scale=1, user-scalable=no",
                        name: "viewport",
                    },
                    { property: "og:title", content: currentMeta.title },
                    { property: "og:site_name", content: "willyou" },
                    {
                        property: "og:url",
                        content: "https://willyou.net" + currentMeta.path,
                    },
                    // {
                    //     property: "og:description",
                    //     content: currentMeta.description,
                    // },
                    { property: "og:type", content: "website" },
                    {
                        property: "og:image",
                        content: "https://willyou.net" + currentMeta.imagePath,
                    },
                    { property: "og:image:width", content: "600" },
                    { property: "og:image:height", content: "315" },

                    // Twitter card
                    // {property: 'twitter:card', content: 'summary'},
                    // {property: 'twitter:title', content: currentMeta.title},
                    // {property: 'twitter:site', content: 'https://willyou.net' + currentMeta.path},
                    // {property: 'twitter:description', content: currentMeta.description},
                    // {name: 'twitter:creator', content: '@willyou'}, //the website owner needs to create twitter account or connect existing one
                    // {property: 'twitter:image:src', content: 'https://willyou.net' + currentMeta.imagePath},
                    // {property: 'twitter:image:width', content: '200'},
                    // {property: 'twitter:image:height', content: '200'},

                    // Google / Schema.org markup:
                    {
                        name: "robots",
                        content: currentMeta.shouldIndex ? "index, follow" : "noindex, nofollow",
                    },
                    { name: "name", content: "willyou" },
                    { name: "title", content: currentMeta.title },
                    { name: "description", content: currentMeta.description },
                    { name: "keywords", content: currentMeta.keywords },
                    {
                        name: "image",
                        content: "https://willyou.net" + currentMeta.imagePath,
                    },
                ],
                link: [
                    {
                        rel: "canonical",
                        href: currentMeta.canonical ? currentMeta.canonical.toLowerCase() : "https://willyou.net" + currentMeta.path.toLowerCase(),
                    },
                ],
            };
        },
    },
};

export default willyou;
