<template>
    <div id="navbar">
        <JewelerAiBanner v-if="!storeMode"></JewelerAiBanner>
        <navbar-top></navbar-top>
        <navbar-bottom v-if="!storeMode || isDiamondsPage"></navbar-bottom>
        <navbar-mobile></navbar-mobile>
    </div>
</template>

<script>
import NavbarTop from "./Top.vue";
import NavbarBottom from "./Bottom.vue";
import NavbarMobile from "./Mobile.vue";
import { mapState } from "vuex";
import JewelerAiBanner from "./JewelerAiBanner.vue";

export default {
    name: "navbar",
    components: {
        "navbar-top": NavbarTop,
        "navbar-bottom": NavbarBottom,
        "navbar-mobile": NavbarMobile,
        JewelerAiBanner,
    },
    computed: {
        ...mapState("willyou", ["storeMode", "isDiamondsPage"]),
    },
};
</script>

<style lang="scss" scoped>
#navbar {
    position: fixed;
    top: 0;
    z-index: 1000;
    right: 0;
    left: 0;
    background: #ffffff;
}
</style>
