<template>
    <div id="iframe-layout">
        <div class="mx-5">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "iframe-layout",
    components: {},
    computed: {
        ...mapState("willyou", []),
    },
    methods: {
        ...mapActions("willyou", {
            initRequest: "initRequest",
            replaceBrowserLocation: "replaceBrowserLocation",
            activateStoreMode: "activateStoreMode",
            iframeLayout: "iframeLayout",
        }),
    },
    mounted() {
        this.initRequest();
        this.activateStoreMode();
        this.iframeLayout();
        // this.$getLocation().then(location => {
        //   return location ? this.replaceBrowserLocation(location) : null;
        // });
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
    .mx-5 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}
</style>
