<template>
  <div id="dashboard-layout">
    <top-alert></top-alert>
    <div v-if="authenticated && !$route.meta.guestOnly">
      <mobile></mobile>
      <div v-if="!isNavbarCollapseOpen" class="main-area">
        <sidebar></sidebar>
        <div class="content">
          <top></top>
          <div class="py-4 px-2 px-lg-4">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!authenticated && $route.meta.guestOnly">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Top from "./navbar/Top";
  import Mobile from "./navbar/Mobile";
  import Sidebar from "./sidebar/Sidebar";
  import TopAlert from "./TopAlert";

  export default {
    name: 'dashboard-layout',
    components: {
      TopAlert,
      'top': Top,
      'mobile': Mobile,
      'sidebar': Sidebar,
    },
    computed: {
      ...mapState('willyou', [
        'isNavbarCollapseOpen',
      ]),
      ...mapState('dashboard', [
        'authenticated',
      ]),
    },
    methods: {
      ...mapActions('dashboard', {
        frontendRegistration: 'frontendRegistration',
      }),
    },
    mounted() {
      this.frontendRegistration();
    },
  }
</script>

<style lang="scss" scoped>
  .main-area {
    display: flex;
    align-items: stretch;
    padding-top: 80px;
    .content {
      width: 100%;
      transition: all 0.3s;
    }
  }
  @media (max-width: 991px) {
    .main-area {
      padding-top: 60px;
    }
  }
</style>
