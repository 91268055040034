import Vue from "vue";
import Vuex from "vuex";
import willyou from "./willyou/store";
import dashboard from "./dashboard/store";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        willyou,
        dashboard,
    },
});

export default store;
