import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";

import VueRouter from "vue-router";
import store from "./store.js";
import App from "./App.vue";
// import JQuery from 'jquery';
import Meta from "vue-meta";
import LottieAnimation from "lottie-web-vue";

// window.$ = JQuery.$ = JQuery;
import "popper.js";
import "bootstrap";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "../node_modules/pretty-checkbox/src/pretty-checkbox.scss";
import "../node_modules/slick-carousel/slick/slick.css";
import "./willyou/styles/willyou.scss";
import "./dashboard/styles/dashboard.scss";
import localforage from "localforage";
import * as memoryDriver from "localforage-driver-memory";

import VueLazyload from "vue-lazyload";
import vSelect from "vue-select";
import VueScrollTo from "vue-scrollto";
import Siema from "vue2-siema";

import { sync } from "vuex-router-sync";
sync(store, router);
import router from "./routes.js";

import "../node_modules/animate.css/animate.css";
import "../node_modules/vue-select/src/scss/vue-select.scss";

import AOS from "aos";
import "aos/dist/aos.css";
import VueSweetalert from "vue-sweetalert2";
import VueClipboard from "vue-clipboard2";
import "./lib-helpers/modernizr-custom";

// import VueGeolocation from 'vue-browser-geolocation';
// import * as VueGoogleMaps from 'vue2-google-maps';
// import Rollbar from 'rollbar';
// import * as FullStory from '@fullstory/browser';

// FullStory.init({ orgId: 'Y15E4' });
// Vue.prototype.$FullStory = FullStory;
// Rollbar was temporarily disabled for our FED 13/10/20
// Vue.prototype.$rollbar = new Rollbar({
//     accessToken: '0a4a9528f9a646238217a4630a689a55',
//     captureUncaught: true,
//     captureUnhandledRejections: true,
// });
// Vue.config.errorHandler = (err, vm) => {
// vm.$rollbar.error(err);
// throw err; // rethrow
// };

// Vue.use(VueGeolocation);

Vue.component("v-select", vSelect);
Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: "/images/store/default.png",
    loading: "/images/store/default.png",
    attempt: 1,
});
Vue.use(VueSweetalert);
Vue.use(VueScrollTo);
Vue.use(VueClipboard);
Vue.use(LottieAnimation);
Vue.use(Siema);
Vue.use(VueGtm, {
    id: "GTM-58C638H", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
    // queryParams: {
    //     // Add url query string when load gtm.js with GTM ID (optional)
    //     gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //     gtm_preview: 'env-4',
    //     gtm_cookies_win: 'x'
    // },
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

Vue.config.productionTip = false;

localforage.defineDriver(memoryDriver);
localforage.config({
    driver: [localforage.LOCALSTORAGE, memoryDriver._driver],
    storeName: "WillYou",
});

new Vue({
    created() {
        AOS.init();
    },
    render: (h) => h(App),
    router,
    store,
}).$mount("#app");
