<template>
    <div id="navbar-mobile" class="d-lg-none">
        <nav class="navbar nav-rnd navbar-expand-lg">
            <router-link v-if="!storeMode || isDiamondsPage" class="navbar-brand" to="/">
                <img alt="willyou.net-logo" src="../../../assets/images/navbar/willyou.svg" class="navbar-brand-logo" />
            </router-link>
            <router-link v-if="storeMode" class="navbar-brand" :to="getStoreModeLink">
                <img :alt="getCompanyName" :src="getCompanyLogo" class="navbar-brand-logo" />
            </router-link>
            <span class="mobile-nav-title">{{ getCompanyName }}</span>
            <button
                v-if="!storeMode || isDiamondsPage"
                ref="hamburger-btn"
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#mobile-nav-collapse"
                aria-controls="mobile-nav-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="changeCollapseStatus">
                <span class="navbar-toggler-icon">
                    <img v-show="!isNavbarCollapseOpen" alt="Menu" src="../../../assets/images/icons/menu.svg" class="navbar-regular-logo" />
                    <img v-show="isNavbarCollapseOpen" alt="X" src="../../../assets/images/icons/close.svg" class="navbar-regular-logo" />
                </span>
            </button>
            <div class="collapse navbar-collapse" v-if="!storeMode || isDiamondsPage" id="mobile-nav-collapse">
                <div class="flex-center p-5">
                    <ul v-if="$route.name !== 'minisite'" class="nav flex-column">
                        <li class="nav-item delay-5">
                            <router-link
                                :to="{ name: 'find-a-jeweler-by-state' }"
                                :class="{
                                    'nav-link-rnd-selected': $route.name === 'find-a-jeweler-by-state',
                                }"
                                class="nav-link nav-link-rnd text-uppercase"
                                @click.native="onLinkClick"
                                >Store Locator
                            </router-link>
                        </li>
                        <li class="nav-item delay-6">
                            <router-link
                                to="/blog"
                                :class="{ 'nav-link-rnd-selected': $route.name === 'blog' }"
                                class="nav-link nav-link-rnd text-uppercase"
                                @click.native="onLinkClick"
                                >BLOG
                            </router-link>
                        </li>
                        <li class="nav-item delay-7">
                            <router-link
                                :to="{ name: 'about' }"
                                :class="{ 'nav-link-rnd-selected': $route.name === 'about' }"
                                class="nav-link nav-link-rnd text-uppercase"
                                @click.native="onLinkClick"
                                >ABOUT
                            </router-link>
                        </li>
                    </ul>
                    <ul v-if="$route.name === 'minisite'" class="nav flex-column">
                        <li class="nav-item delay-2">
                            <router-link
                                to="#"
                                :class="{
                                    'nav-link-rnd-selected': $route.name === 'shopping-bag',
                                }"
                                class="nav-link nav-link-rnd"
                                @click.native="onLinkClick"
                                >LOGIN
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    name: "navbar-mobile",
    data() {
        return {
            isCollapseOpen: false,
        };
    },
    computed: {
        ...mapState("willyou", ["isNavbarCollapseOpen", "currentStore", "storeMode", "isDiamondsPage"]),
        ...mapGetters("willyou", ["startWithRingLink", "startWithDiamondLink", "wishlistLink"]),
        getCompanyName() {
            return this.currentStore && this.$route.name === "home-store" ? this.currentStore.name : "";
        },
        getStoreModeLink() {
            return this.currentStore && this.currentStore.url && this.storeMode
                ? {
                      name: "live-showcase",
                      url: this.currentStore.url,
                  }
                : "/";
        },
        getCompanyLogo() {
            return this.currentStore && this.currentStore.images && this.currentStore.images.logo ? this.currentStore.images.logo : null;
        },
    },
    methods: {
        ...mapActions("willyou", {
            openNavbarCollapse: "openNavbarCollapse",
            closeNavbarCollapse: "closeNavbarCollapse",
        }),
        changeCollapseStatus() {
            this.isNavbarCollapseOpen ? this.closeNavbarCollapse() : this.openNavbarCollapse();
        },
        onLinkClick() {
            this.$refs["hamburger-btn"].click();
        },
    },
};
</script>

<style lang="scss" scoped>
#navbar-mobile {
    .sub-menu {
        font-weight: 300;
    }
    .nav-rnd {
        font-weight: 600;
        position: absolute;
        right: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.9);

        .mobile-nav-title {
            font-family: "Noto Sans", sans-serif;
        }

        .navbar-brand-logo {
            height: 32px;
        }

        .no-text {
            height: 40px;
        }

        .nav-item {
            text-align: center;

            a {
                font-family: "Noto Sans", sans-serif;
                color: #4a4a4a;
            }
        }

        .nav-link-rnd:hover {
            color: #dc2b4a;
        }

        .nav-link-rnd:focus,
        .nav-link-rnd:active {
            color: #fd8498 !important;
        }
    }
}
</style>
