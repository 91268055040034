import VueRouter from "vue-router";
import willyouRouter from "./willyou/routes";
import dashboardRouter from "./dashboard/routes";
import willyouBeforeEach from "./willyou/beforeEach";
import beforeEach from "./dashboard/beforeEach";

const routes = [...willyouRouter, ...dashboardRouter];
const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        return to.name !== from.name ? { x: 0, y: 0 } : null;
    },
});

router.beforeEach(willyouBeforeEach);
router.beforeEach(beforeEach);

export default router;
