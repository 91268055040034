<template>
    <div class="jeweler-ai-banner-wrapper">
        <Lottie class="lottie" />
        <a href="https://willyou.com/jewelerai" rel="noopener noreferrer">
            <h3 class="noto-sans-font-title">
                {{ computedText }} <b class="noto-sans-kr">{{ computedBoldText }}</b>
            </h3>
        </a>
    </div>
</template>

<script>
export default {
    name: "JewelerAiBanner",
    components: {
        Lottie: () => import("@/willyou/components/Lottie.vue"),
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        computedText() {
            if (this.windowWidth > 800) return "FIND YOUR DREAM ENGAGEMENT RING WITH AI";
            else if (this.windowWidth <= 800 && this.windowWidth > 624) return "DISCOVER YOUR PERFECT RING!";
            else return "";
        },
        computedBoldText() {
            if (this.windowWidth > 590) return "TRY JEWELER AI NOW!";
            else return "FIND YOUR RING NOW!";
        },
    },
    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener("resize", this.updateWindowWidth);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateWindowWidth);
    },
};
</script>

<style lang="scss" scoped>
.jeweler-ai-banner-wrapper {
    cursor: pointer;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(224, 248, 242);

    .lottie {
        width: 40px;
        height: 40px;
        margin-right: 16px;
    }
    h3 {
        color: #293887;
        font-weight: 700;
        margin-bottom: 0;
        font-size: 16px;
        padding: 0;
        font-weight: 500;
        letter-spacing: 2px;
    }
    b {
        margin-left: 14px;
        text-decoration: underline;
    }
}
</style>
