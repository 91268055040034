<template>
    <div id="footer-mobile" class="d-md-none">
        <footer class="footer page-footer font-small">
            <div v-if="$route.name !== 'minisite'" class="container footer-container">
                <div class="row justify-content-start">
                    <div class="col-xl col-lg col-md-4 col-xs-12 col-sm-12">
                        <div class="footer-list-area">
                            <span
                                class="btn btn-link footer-collapse-btn d-block"
                                data-toggle="collapse"
                                data-target="#collapse-about"
                                aria-expanded="true"
                                aria-controls="collapse-about"
                                @click="isAboutCollapseOpen = !isAboutCollapseOpen">
                                <router-link :to="{ name: 'find-a-jeweler-by-state' }">
                                    <p class="text-uppercase list-heading">Store Locator</p>
                                </router-link>
                            </span>
                        </div>
                    </div>
                    <div class="col-xl col-lg col-md-4 col-xs-12 col-sm-12">
                        <div class="footer-list-area">
                            <span
                                class="btn btn-link footer-collapse-btn"
                                data-toggle="collapse"
                                data-target="#collapse-contact"
                                aria-expanded="true"
                                aria-controls="collapse-contact"
                                @click="isContactCollapseOpen = !isContactCollapseOpen">
                                <router-link to="/engagement-rings">
                                    <p class="text-uppercase list-heading">Engagement Rings</p>
                                </router-link>
                            </span>
                        </div>
                    </div>
                    <div class="col-xl col-lg col-md-4 col-xs-12 col-sm-12">
                        <div class="footer-list-area">
                            <span
                                class="btn btn-link footer-collapse-btn"
                                data-toggle="collapse"
                                data-target="#collapse-customer"
                                aria-expanded="true"
                                aria-controls="collapse-customer"
                                @click="isCustomerCollapseOpen = !isCustomerCollapseOpen">
                                <router-link :to="{ path: $route.path }">
                                    <p class="text-uppercase list-heading">BLOG</p>
                                </router-link>
                            </span>
                        </div>
                    </div>
                    <div class="col-xl col-lg col-md-4 col-xs-12 col-sm-12">
                        <div class="footer-list-area">
                            <span
                                class="btn btn-link footer-collapse-btn"
                                data-toggle="collapse"
                                data-target="#collapse-customer"
                                aria-expanded="true"
                                aria-controls="collapse-customer"
                                @click="isCustomerCollapseOpen = !isCustomerCollapseOpen">
                                <router-link :to="{ path: $route.path }">
                                    <p class="text-uppercase list-heading">ABOUT</p>
                                </router-link>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="col-8 text-center mx-auto">
                            <!-- <social-networks></social-networks> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-copyright text-center py-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <router-link :to="{ name: 'terms-of-use' }">Terms of Use</router-link>
                            <span class="px-1"> | </span>
                            <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
                            <span class="px-1"> | </span>
                            <router-link :to="{ name: 'dmca-policy' }">DMCA Policy</router-link>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col">
                            <span class="text-left rights-signature">© willyou.net 2025</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
// import SocialNetworks from "../SocialNetworks.vue";

export default {
    name: "footer-mobile",
    // components: { SocialNetworks },
};
</script>

<style lang="scss" scoped>
#footer-mobile {
    .footer {
        font-family: "Noto Sans KR", sans-serif;
        position: relative;
        bottom: 0;
        width: 100%;
        background-image: linear-gradient(to top, #ffffff, #f7f7f7);
        .footer-container {
            padding: 40px 0 20px 0;
            .list-heading {
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4a4a4a;
                font-size: 16px;
                margin-top: 30px;
                display: inline;
            }
            .footer-list {
                padding: 0 10px;
            }
            .footer-list-element {
                padding-top: 30px;
                a {
                    color: rgba(74, 74, 74, 0.87);
                }
            }
        }
        .footer-copyright {
            background-color: #767d98;
            color: #fff;
            a {
                color: #ffffff;
            }
        }
        .footer-collapse-btn {
            width: 100%;
            text-align: left;
            padding: 0 10px;
        }
        .footer-collapse-btn:active {
        }
        .rotate-drop {
            transform: rotate(180deg);
        }
        .footer-list-area {
            padding: 15px 0;
            margin: 0 30px;
            border-top: 1px solid #767d9866;
        }
        .rights-signature {
            color: rgba(255, 255, 255, 0.6);
        }
    }
}
</style>
