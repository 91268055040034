const willyouRouter = [
    {
        path: "/",
        component: () => import("./views/home/Home.vue"),
        name: "home",
        children: [
            {
                path: "/terms-of-use",
                component: () => import("./components/terms-popups/TermsOfUse.vue"),
                name: "terms-of-use",
            },
            {
                path: "/privacy-policy",
                component: () => import("./components/terms-popups/PrivacyPolicy.vue"),
                name: "privacy-policy",
            },
            {
                path: "/dmca-policy",
                component: () => import("./components/terms-popups/DMCAPolicy.vue"),
                name: "dmca-policy",
            },
        ],
    },
    {
        path: "/diamonds",
        component: () => import("./views/diamondsPage/DiamondsPage.vue"),
        props: (route) => ({
            routeProps: {
                page: route.query.page,
                shapes: route.query.shapes,
                clarity: route.query.clarity,
                colors: route.query.colors,
                cuts: route.query.cuts,
                minbudget: route.query.minbudget,
                maxbudget: route.query.maxbudget,
                mincarat: route.query.mincarat,
                maxcarat: route.query.maxcarat,
                sort: route.query.sort,
            },
        }),
        name: "diamonds-page",
    },
    {
        path: "/diamonds/schedule-an-appointment",
        component: () => import("./views/live-showcase/LiveShowcase.vue"),
        name: "diamonds-page-schedule-popup",
        meta: {
            layout: (route) => (typeof route.query !== "undefined" && typeof route.query.iframe !== "undefined" ? "iframe-layout" : "willyou-layout"),
        },
        props: (route) => ({
            url: "diamonds",
        }),
    },
    {
        path: "/diamonds/:diamondUrl",
        component: () => import("./views/live-showcase/LiveShowcase.vue"),
        name: "diamonds-page-diamond-popup",
        meta: {
            layout: (route) => "willyou-layout",
        },
        props: (route) => ({
            url: "diamonds",
            diamondUrl: route.params.diamondUrl,
            page: route.query.page,
        }),
    },
    {
        path: "/engagement-rings/popular-engagement-rings",
        component: () => import("./views/home/sections/PopularRings.vue"),
        name: "popular-engagement-rings-iframe",
        meta: {
            layout: "iframe-layout",
        },
    },
    {
        path: "/engagement-rings/best-engagement-rings",
        component: () => import("./views/home/sections/PopularRings.vue"),
        meta: {
            layout: "iframe-layout",
        },
        name: "popular-engagement-rings-iframe-best",
    },
    {
        path: "/engagement-rings/top-engagement-rings-for-women",
        component: () => import("./views/home/sections/PopularRings.vue"),
        name: "popular-engagement-rings-iframe-women",
        meta: {
            layout: "iframe-layout",
        },
    },
    {
        path: "/engagement-rings/top-engagement-ring-sets",
        component: () => import("./views/home/sections/PopularRings.vue"),
        name: "popular-engagement-rings-iframe-sets",
        meta: {
            layout: "iframe-layout",
        },
    },
    {
        path: "/engagement-rings",
        component: () => import("./views/ringsPage/RingsPage.vue"),
        name: "engagement-rings-search",
        props: (route) => ({
            routeProps: {
                searchenginetype: "rings",
                page: route.query.page,
                ringshapes: route.query.ringshapes,
                ringcolors: route.query.ringcolors,
                diamShapes: route.query.diamShapes,
                sort: route.query.sort,
                currentdiamond: route.query.currentdiamond,
            },
        }),
    },
    {
        path: "/engagement-rings/:ringId",
        component: () => import("./views/product/Ring.vue"),
        name: "engagement-rings-search-product-ring",
        props: (route) => ({
            ringId: route.params.ringId,
            isRingsPage: "true",
        }),
    },
    {
        path: "/engagement-rings/schedule-an-appointment",
        component: () => import("./views/live-showcase/LiveShowcase.vue"),
        name: "rings-page-schedule-popup",
        meta: {
            layout: (route) => (typeof route.query !== "undefined" && typeof route.query.iframe !== "undefined" ? "iframe-layout" : "willyou-layout"),
        },
        props: (route) => ({
            url: "rings",
        }),
    },
    {
        path: "/jewelers/:url/live-diamond-feed",
        component: () => import("./views/live-showcase/LiveShowcase.vue"),
        name: "live-showcase",
        meta: {
            layout: (route) => (typeof route.query !== "undefined" && typeof route.query.iframe !== "undefined" ? "iframe-layout" : "willyou-layout"),
        },
        props: (route) => ({
            url: route.params.url,
            routeProps: {
                page: route.query.page,
                shapes: route.query.shapes,
                clarity: route.query.clarity,
                colors: route.query.colors,
                cuts: route.query.cuts,
                minbudget: route.query.minbudget,
                maxbudget: route.query.maxbudget,
                mincarat: route.query.mincarat,
                maxcarat: route.query.maxcarat,
                sort: route.query.sort,
            },
        }),
    },
    {
        path: "/jewelers/:url/live-diamond-feed/schedule-an-appointment",
        component: () => import("./views/live-showcase/LiveShowcase.vue"),
        name: "schedule-popup",
        meta: {
            layout: (route) => (typeof route.query !== "undefined" && typeof route.query.iframe !== "undefined" ? "iframe-layout" : "willyou-layout"),
        },
        props: (route) => ({
            url: route.params.url,
        }),
    },
    {
        path: "/jewelers/:url/live-diamond-feed/:diamondUrl",
        component: () => import("./views/live-showcase/LiveShowcase.vue"),
        name: "diamond-popup",
        meta: {
            layout: (route) => (typeof route.query !== "undefined" && typeof route.query.iframe !== "undefined" ? "iframe-layout" : "willyou-layout"),
        },
        props: (route) => ({
            url: route.params.url,
            diamondUrl: route.params.diamondUrl,
            page: route.query.page,
        }),
    },
    {
        path: "/jewelers/:url/engagement-rings",
        component: () => import("./views/category/rings/Rings.vue"),
        name: "rings",
        props: (route) => ({
            url: route.params.url,
            routeProps: {
                page: route.query.page,
                ringshapes: route.query.ringshapes,
                ringcolors: route.query.ringcolors,
                diamShapes: route.query.diamShapes,
                sort: route.query.sort,
                currentdiamond: route.query.currentdiamond,
            },
        }),
    },
    {
        path: "/jewelers/:url/diamonds",
        component: () => import("./views/category/diamonds/Diamonds.vue"),
        name: "diamonds",
        props: (route) => ({
            url: route.params.url,
            routeProps: {
                page: route.query.page,
                shapes: route.query.shapes,
                clarity: route.query.clarity,
                colors: route.query.colors,
                cuts: route.query.cuts,
                minbudget: route.query.minbudget,
                maxbudget: route.query.maxbudget,
                mincarat: route.query.mincarat,
                maxcarat: route.query.maxcarat,
                sort: route.query.sort,
                resultsPerPage: 12,
            },
        }),
    },
    {
        path: "/jewelers/:url/wishlist",
        component: () => import("./views/wishlist/Wishlist.vue"),
        name: "wishlist",
        props: true,
        children: [
            {
                path: "contact",
                name: "wishlist-contact",
                component: () => import("./views/wishlist/Popup.vue"),
            },
        ],
    },
    {
        path: "/jewelers/:url/product/diamond/:diamondUrl/schedule-an-appointment",
        component: () => import("./views/product/Diamond.vue"),
        name: "jeweler-schedule-popup",
        props: (route) => ({
            url: route.params.url,
            diamondUrl: route.params.diamondUrl,
            schedule: true,
        }),
    },
    {
        path: "/jewelers/:url/product/diamond/:diamondUrl",
        component: () => import("./views/product/Diamond.vue"),
        name: "product-diamond",
        props: (route) => ({
            url: route.params.url,
            diamondUrl: route.params.diamondUrl,
            schedule: false,
        }),
    },
    {
        path: "/jewelers/:url/product/ring/:ringId",
        component: () => import("./views/product/Ring.vue"),
        name: "product-ring",
        props: (route) => ({
            url: route.params.url,
            ringId: route.params.ringId,
        }),
    },
    {
        path: "/jewelers/:url",
        component: () => import("./views/home/HomeStore.vue"),
        name: "home-store",
        props: (route) => ({
            routeProps: {
                searchenginetype: route.query.searchenginetype ? route.query.searchenginetype : "diamonds",
                page: route.query.page,
                shapes: route.query.shapes,
                clarity: route.query.clarity,
                colors: route.query.colors,
                cuts: route.query.cuts,
                minbudget: route.query.minbudget,
                maxbudget: route.query.maxbudget,
                mincarat: route.query.mincarat,
                maxcarat: route.query.maxcarat,
                sort: route.query.sort,
                resultsPerPage: 12,

                ringcolors: route.query.ringcolors,
                ringshapes: route.query.ringshapes,
                diamshapes: route.query.diamshapes,
            },
            url: route.params.url,
        }),
    },
    {
        path: "/jewelry-stores/results",
        component: () => import("./views/store-locator/stores-search-results/SearchResults.vue"),
        name: "stores-search-results-by-location",
        props: (route) => ({
            routeProps: {
                location: route.query.location,
                zipcode: route.query.zipcode,
                page: route.query.page,
            },
        }),
    },
    {
        path: "/jewelry-stores/:stateName/:city",
        component: () => import("./views/store-locator/stores-search-results/SearchResults.vue"),
        name: "stores-search-results-by-city",
        props: (route) => ({
            routeProps: {
                city: route.params.city,
                stateName: route.params.stateName,
                page: route.query.page,
            },
        }),
    },
    {
        path: "/jewelry-stores/:stateName",
        component: () => import("./views/store-locator/find-jeweler/FindJewelerByCity.vue"),
        name: "find-a-jeweler-by-city",
        props: true,
    },
    {
        path: "/jewelry-stores",
        component: () => import("./views/store-locator/find-jeweler/FindJewelerByState.vue"),
        name: "find-a-jeweler-by-state",
    },
    {
        path: "/join/:user",
        component: () => import("./views/minisite/Minisite.vue"),
        name: "minisite-user",
        props: (route) => ({
            routeProps: {
                user: route.params.user,
            },
        }),
    },
    {
        path: "/join",
        component: () => import("./views/minisite/Minisite.vue"),
        name: "minisite",
    },
    {
        path: "/moretraffic/:user",
        component: () => import("./views/minisite/MoreTraffic.vue"),
        name: "more-traffic-user",
        props: (route) => ({
            routeProps: {
                user: route.params.user,
            },
        }),
    },
    {
        path: "/moretraffic",
        component: () => import("./views/minisite/MoreTraffic.vue"),
        name: "more-traffic",
    },
    {
        path: "/under-construction",
        component: () => import("./views/errors/UnderConstruction.vue"),
        name: "under-construction",
    },
    //Redirected route
    {
        path: "/best-place-to-buy-engagement-ring",
        component: () => import("./views/engagement-ring/EngagementRings.vue"),
        name: "engagement-rings",
    },
    {
        path: "/blog",
        component: () => import("./views/blog/BlogLoader.vue"),
        name: "blog",
    },
    {
        path: "/blog/:category/*",
        component: () => import("./views/blog/BlogLoader.vue"),
        name: "blog-sub-category",
    },
    {
        path: "/blog/:category",
        component: () => import("./views/blog/BlogLoader.vue"),
        name: "blog-category",
    },
    {
        path: "/about-us",
        component: () => import("./views/about/About.vue"),
        name: "about",
    },
    {
        path: "/gia-report-certificate-check",
        component: () => import("./views/gia/dynamo/Dynamo.vue"),
        name: "gia-report-certificate-check",
    },
    // {
    //     path: "/gia-report-certificate-check/certificate/:certificate",
    //     component: () => import("./views/gia/search-results/SearchResults.vue"),
    //     name: "gia-certificate-results",
    //     props: (route) => ({
    //         routeProps: {
    //             certificate: route.params.certificate,
    //         },
    //     }),
    // },
    // {
    //     path: "/gia-report-certificate-check/:shape",
    //     component: () => import("./views/gia/certificate/Certificate.vue"),
    //     name: "certificate-shape",
    //     props: (route) => ({
    //         routeProps: {
    //             carat: route.query.carat,
    //         },
    //     }),
    // },
    // {
    //     path: "/gia-report-certificate-check/:shape/:mincarat-carat-:color-:clarity",
    //     component: () => import("./views/gia/search-results/SearchResults.vue"),
    //     name: "gia-results",
    //     props: (route) => ({
    //         routeProps: {
    //             page: route.query.page,
    //             shape: route.params.shape,
    //             mincarat: route.params.mincarat,
    //             maxcarat: route.query.maxcarat,
    //             color: route.params.color,
    //             clarity: route.params.clarity,
    //             sort: route.query.sort,
    //         },
    //     }),
    // },
    {
        path: "/dashboard",
        component: () => import("./views/close-dashboard/CloseDashboard.vue"),
        name: "close-dashboard",
        props: (route) => ({
            routeProps: {
                certificate: route.params.certificate,
            },
        }),
    },
    {
        path: "/login",
        component: () => import("./views/close-dashboard/CloseDashboard.vue"),
        name: "close-dashboard-login",
        props: (route) => ({
            routeProps: {
                certificate: route.params.certificate,
            },
        }),
    },
    {
        path: "*",
        component: () => import("./views/errors/PageNotFound.vue"),
        name: "page-not-found",
    },
];

export default willyouRouter;
