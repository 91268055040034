<template>
    <div class="sub-navbar">
        <ul class="sub-menu">
            <router-link :to="link.src" v-bind:key="linkKey" v-for="(link, linkKey) in linksObject">
                <li :class="{ 'nav-link-rnd-selected': $route.name === link.activatedByRoute }">
                    {{ link.name }}
                </li>
            </router-link>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "navbar",
    props: ["linksObject"],
    computed: {
        ...mapState("willyou", ["storeMode", "isDiamondsPage"]),
    },
};
</script>
<style lang="scss" scoped>
.sub-navbar {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
    ul {
        padding: 0;
        box-shadow: 0 2px 4px 4px rgb(0 0 0 / 5%);
        position: absolute;
        width: fit-content;
        left: calc(-135px + 50%);
    }
    li {
        min-width: 270px;
        width: auto;
        height: 40px;
        font-size: 14px;
        font-family: "Noto Sans", sans-serif;
        list-style: none;
        padding: 9px 0;
        background-color: rgba(255, 255, 255, 0.88);
        text-align: center;
        font-weight: 300;
        color: #4a4a4a;
        color: rgba(0, 0, 0, 0.5);
        display: block;
        line-height: normal;
    }
    li:hover {
        background-color: rgba(243, 243, 243, 0.88);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    }
}
</style>
