import localforage from "localforage";

const beforeEach = ((to, from, next) => {
    localforage.getItem('authtoken').then(token => {
        if (!token && to.meta && to.meta.needsAuth) {
            return next({ name: 'login' });
        } else if (to.meta && to.meta && to.meta.guestOnly && token) {
            return next({ name: 'dashboard' });
        } else {
            next();
        }
    });
});

export default beforeEach;
