import axios from "axios";
import localforage from "localforage";
import isEmpty from "lodash/isEmpty";

const dashboard = {
    namespaced: true,
    state: {
        baseApiUrl: "/api/dashboard",
        // baseApiUrl: "http://localhost:8000/api/dashboard",
        baseApiUrlDemo: "/demo/dashboard",
        authenticated: false,
        isLoading: false,
        store: {},
        score: {},
        inqueires: [],
        diamondMarkup: {},
        objectAlert: null,
    },
    mutations: {
        SET_AUTHENTICATION(state, isAuthenticated) {
            state.authenticated = isAuthenticated;
        },
        SET_LOADING(state, boolValue) {
            state.isLoading = boolValue;
        },
        SET_STORE_DATA(state, store) {
            state.store = store;
        },
        SET_STORE_SCORE(state, score) {
            state.score = score;
        },
        SET_INQUEIRES(state, inqueires) {
            state.inqueires = inqueires;
        },
        SET_DIAMOND_MARKUP(state, object) {
            state.diamondMarkup = object;
        },
        SET_OBJECT_ALERT(state, object) {
            state.objectAlert = object;
        },
    },
    actions: {
        // toggleLoading({ commit }, status) {
        //     commit('SET_LOADING', status === true);
        // },
        errorAlert({ commit }, message) {
            commit("SET_OBJECT_ALERT", { type: "error", message });
            setTimeout(() => commit("SET_OBJECT_ALERT", null), 10000);
        },
        successAlert({ commit }, message = "Successfully saved") {
            commit("SET_OBJECT_ALERT", { type: "success", message });
            setTimeout(() => commit("SET_OBJECT_ALERT", null), 10000);
        },
        setAxiosHttpToken({ commit }, token) {
            axios.defaults.headers.common["Authorization"] = token ? `Bearer${token}` : null;
        },
        setToken({ commit }, token) {
            return token ? localforage.setItem("authtoken", token) : localforage.removeItem("authtoken");
        },
        signOut({ commit, dispatch }) {
            commit("SET_AUTHENTICATION", false);
            commit("SET_STORE_DATA", null);
            dispatch("setToken", null);
            location.reload();
        },
        frontendRegistration({ commit, dispatch }) {
            localforage.getItem("authtoken").then((token) => {
                commit("SET_AUTHENTICATION", !!token);
                dispatch("setAxiosHttpToken", token);
                token ? dispatch("init") : null;
            });
        },
        init({ state, dispatch }, force = false) {
            isEmpty(state.store) || force
                ? axios
                      .get(`${state.baseApiUrl}/init`)
                      .then((response) => {
                          response.data.isConnected ? dispatch("updateBasicData", response.data) : dispatch("signOut");
                      })
                      .catch((e) => {
                          return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                      })
                : null;
        },
        login({ dispatch, state }, { payload, router }) {
            axios
                .post(`${state.baseApiUrl}/login`, payload)
                .then((response) => {
                    response.data.error ? dispatch("errorAlert", response.data.error.error) : null;
                    const token = response.data ? response.data.token : null;
                    return token
                        ? dispatch("setToken", token).then(() => {
                              dispatch("frontendRegistration");
                              dispatch("updateBasicData", response.data);
                              router.replace({ name: "details" });
                          })
                        : null;
                })
                .catch((e) => {
                    return e && e.response && e.response.status === 401 ? dispatch("errorAlert", "Wrong details") : null;
                });
        },
        crmLogin({ commit, dispatch, state }, { payload, router }) {
            commit("SET_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/crmlogin`, payload)
                .then((response) => {
                    const token = response.data ? response.data.token : null;
                    commit("SET_LOADING", false);

                    return token
                        ? dispatch("setToken", token).then(() => {
                              dispatch("frontendRegistration");
                              dispatch("updateBasicData", response.data);
                              payload.isForgotPassword ? router.replace({ name: "change-password-reset" }) : router.replace({ name: "details" });
                          })
                        : null;
                })
                .catch((e) => {
                    commit("SET_LOADING", false);

                    return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                });
        },
        register({ dispatch, state }, { payload, router }) {
            payload.sourceUrl = window.location.href;

            return axios
                .post(`${state.baseApiUrl}/register`, payload)
                .then((response) => {
                    return response.data.error ? dispatch("errorAlert", response.data.error.error) : response.data;
                })
                .catch((e) => {
                    return e && e.response && e.response.status === 401 ? dispatch("errorAlert", "Wrong details") : null;
                });
        },
        updateBasicData({ commit }, payload) {
            if (payload && !payload.isConnected) {
                return null;
            } else {
                payload && payload.store ? commit("SET_STORE_DATA", payload.store) : null;
                payload && payload.inqueires ? commit("SET_INQUEIRES", payload.inqueires) : null;
                payload && payload.score ? commit("SET_STORE_SCORE", payload.score) : null;
            }
        },
        sendStoreDetailsForm({ dispatch, commit, state }, localData) {
            commit("SET_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/store-details`, localData)
                .then((response) => {
                    response.data.success ? dispatch("successAlert") : dispatch("errorAlert", response.data.error.error);
                    dispatch("updateBasicData", localData);
                    response.data && response.data.store ? commit("SET_STORE_DATA", response.data.store) : null;
                    commit("SET_LOADING", false);
                })
                .catch((e) => {
                    return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                });
        },
        getDiamondMarkup({ state, commit, dispatch }, action) {
            commit("SET_LOADING", true);
            axios
                .get(`${state.baseApiUrl}/diamond-markup`)
                .then((response) => {
                    commit("SET_LOADING", false);
                    response && response.data ? commit("SET_DIAMOND_MARKUP", response.data) : null;
                    response && response.data ? action(response.data) : null;
                })
                .catch((e) => {
                    commit("SET_LOADING", false);
                    return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                });
        },
        sendDiamondMarkupForm({ state, commit, dispatch }, localData) {
            commit("SET_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/diamond-markup`, localData)
                .then((response) => {
                    response.data.success ? dispatch("successAlert") : dispatch("errorAlert", response.data.error.error);
                    commit("SET_LOADING", false);
                    commit("SET_DIAMOND_MARKUP", localData);
                    response.data && response.data.store ? commit("SET_STORE_DATA", response.data.store) : null;
                })
                .catch((e) => {
                    commit("SET_LOADING", false);
                    return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                });
        },
        updateAboutStore({ state, commit }, payload) {
            let newStore = state.store;
            newStore.history = payload.history;
            newStore.questionsAndAnswers = payload.questionsAndAnswers;
            commit("SET_STORE_DATA", newStore);
        },
        sendAboutStoreForm({ state, commit, dispatch }, localData) {
            commit("SET_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/about-the-store`, localData)
                .then((response) => {
                    response.data.success ? dispatch("successAlert") : dispatch("errorAlert", response.data.error.error);
                    commit("SET_LOADING", false);
                    dispatch("updateAboutStore", localData);
                    response.data && response.data.store ? commit("SET_STORE_DATA", response.data.store) : null;
                })
                .catch((e) => {
                    commit("SET_LOADING", false);
                    return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                });
        },
        sendOnBoardingForm({ state, commit, dispatch }, { payload, router }) {
            commit("SET_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/on-boarding`, payload)
                .then((response) => {
                    response.data.success ? dispatch("successAlert") : dispatch("errorAlert", response.data.error.error);
                    commit("SET_LOADING", false);
                    commit("SET_STORE_DATA", payload.store);
                    commit("SET_DIAMOND_MARKUP", payload.diamondMarkup);
                    router.replace({ name: "dashboard" });
                })
                .catch((e) => {
                    commit("SET_LOADING", false);
                    return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                });
        },
        sendChangePassword({ state, commit, dispatch }, { payload }) {
            commit("SET_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/change-password`, payload)
                .then((response) => {
                    response.data.success ? dispatch("successAlert") : dispatch("errorAlert", response.data.error.error);
                    commit("SET_LOADING", false);
                })
                .catch((e) => {
                    commit("SET_LOADING", false);
                    return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                });
        },
        updateStoreReferences({ state, commit }, payload) {
            let newStore = state.store;
            newStore.references = payload.references;
            commit("SET_STORE_DATA", newStore);
        },
        sendReferencesForm({ state, commit, dispatch }, localData) {
            commit("SET_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/references`, localData)
                .then((response) => {
                    response.data.success ? dispatch("successAlert") : dispatch("errorAlert", response.data.error.error);
                    commit("SET_LOADING", false);
                    dispatch("updateStoreReferences", localData);
                    response.data && response.data.store ? commit("SET_STORE_DATA", response.data.store) : null;
                })
                .catch((e) => {
                    commit("SET_LOADING", false);
                    return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                });
        },
        forgotPassword({ state, dispatch }, { payload }) {
            axios.post(`${state.baseApiUrl}/forgot-password`, payload).then((response) => {
                return response.data.success ? dispatch("successAlert", response.data.message) : dispatch("errorAlert", response.data.error.error);
            });
        },
        updateLead({ state, commit, dispatch }, { payload }) {
            commit("SET_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/update-lead`, payload)
                .then((response) => {
                    response.data.success ? dispatch("successAlert") : dispatch("errorAlert", response.data.error.error);
                    commit("SET_LOADING", false);
                    response.data && response.data.store ? commit("SET_STORE_DATA", response.data.store) : null;
                })
                .catch((e) => {
                    commit("SET_LOADING", false);
                    return e && e.response && e.response.status === 401 ? dispatch("signOut") : null;
                });
        },
    },
    getters: {
        aboutStore(state) {
            return !isEmpty(state.store)
                ? {
                      aboutTheStoreDescription: state.store.aboutTheStoreDescription,
                      aboutTheStore: state.store.dashboardAboutTheStore,
                      photo1: state.store.images ? state.store.images.photo1 : null,
                      photo2: state.store.images ? state.store.images.photo2 : null,
                      photo3: state.store.images ? state.store.images.photo3 : null,
                  }
                : {};
        },
        references(state) {
            return !isEmpty(state.store) ? state.store.references : {};
        },
    },
};

export default dashboard;
