<template>
    <div id="app-footer">
        <footer-desktop></footer-desktop>
        <footer-mobile></footer-mobile>
    </div>
</template>

<script>
import FooterDesktop from "./Desktop.vue";
import FooterMobile from "./Mobile.vue";

export default {
    name: "app-footer",
    components: {
        "footer-desktop": FooterDesktop,
        "footer-mobile": FooterMobile,
    },
};
</script>
