<template>
    <div
            id="top-alert"
            v-if="objectAlert"
            class="alert alert-dismissible fade show w-100 d-inline-block"
            :class="`alert-${objectAlert.type}`"
            role="alert"
    >
        <div class="row tbl">
                    <img :class="`${objectAlert.type}`"
                            v-if="objectAlert.type === 'success'"
                            src="../../assets/images/icons/success.svg"
                            :alt="`${objectAlert.type}`"
                    >
                    <img :class="`${objectAlert.type}`"
                            v-else-if="objectAlert.type === 'error'"
                            src="../../assets/images/icons/error.svg"
                            :alt="`${objectAlert.type}`"
                    >
                    <img :class="`${objectAlert.type}`"
                            v-else
                            src="../../assets/images/icons/info.svg"
                            :alt="`${objectAlert.type}`"
                    >
                <span class="message-body">{{objectAlert.message}}</span>
            <button
                    type="button"
                    class="close m-1"
                    data-dismiss="alert"
                    aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: "top-alert",
        computed: {
            ...mapState('dashboard', [
                'objectAlert',
            ]),
        },
    };
</script>

<style lang="scss" scoped>
    #top-alert {
        z-index: 10000;
        font-family: "Noto Sans", sans-serif;
        position: fixed;
        top: 0;
        left: 0;
    }
    .tbl {
        display: table;
    }
    img {
        display: table-cell;
        vertical-align: middle;
        border-radius: 60px;
        padding: 0.5em 0.6em;
        margin-left: 0.6em;
        height: 43px;
        width: 43px;
    }
    img.basic {
        background: #fff7df;
        mix-blend-mode: multiply;
    }
    img.error {
        background: #ffd4d3;
        mix-blend-mode: multiply;
    }
    img.success {
        background: #effcf6;
        mix-blend-mode: multiply;
    }
    .message-body{
        display: table-cell;
        vertical-align: middle;
        padding: 0 0 0 0.6em;
        width: 70vw;
    }
    button {
        top: 0.5em;
        right: 0.6em;
    }
    .alert-basic {
        background-color: #dceefb;
        color: #0f609b;
    }
    .alert-error {
        background-color: #ffd4d3;
        color: #8f2221;
    }
    .alert-success {
        background-color: #effcf6;
        color: #137d64;
    }
</style>
