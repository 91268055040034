<template>
    <div id="navbar-bottom" class="d-none d-lg-block">
        <ul class="nav justify-content-center nav-rnd nav-bottom-rnd">
            <li class="nav-item nav-item-rnd">
                <router-link
                    :class="{
                        'nav-link-rnd-selected': $route.name === 'find-a-jeweler-by-state',
                    }"
                    class="nav-link nav-link-rnd text-uppercase"
                    :to="{ name: 'find-a-jeweler-by-state' }"
                    >Store Locator</router-link
                >
                <sub-navbar :linksObject="getLinks('Store Locator')"></sub-navbar>
            </li>
            <!-- <li class="nav-item nav-item-rnd">
                <router-link
                    :class="{
                        'nav-link-rnd-selected': $route.name === 'engagement-rings-search',
                    }"
                    class="nav-link nav-link-rnd text-uppercase"
                    :to="{ name: 'engagement-rings-search' }"
                    >Engagement Rings</router-link
                >
                <sub-navbar :linksObject="getLinks('Engagement Rings')"></sub-navbar>
            </li>
            <li class="nav-item nav-item-rnd">
                <router-link
                    :class="{ 'nav-link-rnd-selected': $route.name === 'diamonds-page' }"
                    class="nav-link nav-link-rnd text-uppercase"
                    :to="{ name: 'diamonds-page' }"
                    >Diamonds</router-link
                >
                <sub-navbar :linksObject="getLinks('Diamonds')"></sub-navbar>
            </li> -->

            <li class="nav-item nav-item-rnd">
                <router-link :class="{ 'nav-link-rnd-selected': $route.name === 'blog' }" class="nav-link nav-link-rnd text-uppercase" :to="{ name: 'blog' }"
                    >BLOG</router-link
                >
                <sub-navbar :linksObject="getLinks('Blog')"></sub-navbar>
            </li>
            <li class="nav-item nav-item-rnd">
                <router-link :class="{ 'nav-link-rnd-selected': $route.name === 'about' }" class="nav-link nav-link-rnd text-uppercase" :to="{ name: 'about' }"
                    >ABOUT</router-link
                >
                <sub-navbar :linksObject="getLinks('About')"></sub-navbar>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import subNavbarVue from "./sub-navbar/subNavbar.vue";

export default {
    name: "navbar-bottom",
    components: { "sub-navbar": subNavbarVue },
    computed: {
        ...mapGetters("willyou", ["startWithRingLink", "startWithDiamondLink"]),
    },
    methods: {
        getLinks(page) {
            let returnCode = "";
            switch (page.toLowerCase()) {
                case "store locator":
                    returnCode = [];
                    break;
                case "engagement rings":
                    returnCode = [];
                    break;
                case "diamonds":
                    returnCode = [
                        {
                            src: { name: "gia-report-certificate-check" },
                            name: "GIA Diamond Lookup",
                            activatedByRoute: "gia-report-certificate-check",
                        },
                    ];
                    break;
                case "blog":
                    returnCode = [];
                    break;
                case "about":
                    returnCode = [
                        // {src: 'test', name: 'GIA Report Certificate Check'},
                        // {src: 'test', name: 'GIA Report Certificate Check', activatedByRoute:'about'},
                    ];
                    break;
            }
            return returnCode;
        },
    },
};
</script>

<style lang="scss" scoped>
#navbar-bottom {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 1001;
    .nav-rnd {
        background-color: rgba(255, 255, 255, 0.88);
        position: absolute;
        right: 0;
        top: 59px;
        left: 0;
    }
    .nav-item:hover .sub-navbar {
        visibility: visible;
        opacity: 1;
    }
    .nav-item-rnd {
        margin: 5px 30px 5px 30px;
        .nav-link-rnd {
            font-family: "Noto Sans", sans-serif;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: 2px;
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
        }
        .nav-link-rnd:hover {
            color: #dc2b4a;
        }
        .nav-link-rnd:focus,
        .nav-link-rnd:active,
        .nav-item-rnd.selected {
            color: #fd8498;
        }
    }
    .nav-bottom-rnd {
        z-index: 1;
    }
}
</style>
